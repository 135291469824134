import { pipe } from 'ramda'
import { connect } from 'react-redux'

import maspStateToProps from './remote-screen.selector'
import { RemoteScreenActions } from '../../store'
import { FileExplorerActions } from 'Features/FileExplorer'
import RemoteScreen from './remote-screen'

const mapDispatchToProps = dispatch => ({
  setFileExplorerLoading: (loading) => dispatch(FileExplorerActions.updateFileExplorerLoading(loading)),
  updateDeviceManufacturerInfo: (deviceManufacturerInfo) => dispatch(FileExplorerActions.updateDeviceManufacturerInfo(deviceManufacturerInfo)),
  updateFileExplorerAvailability: (isAvailable, agentVersion) => dispatch(FileExplorerActions.updateFileExplorerAvailability(isAvailable, agentVersion)),
  updateDataChannelStatus: (status) => dispatch(RemoteScreenActions.updateDataChannelStatus(status)),
  updateUploadOrDeleteResponse: (data, childrenFiles, path) => dispatch(FileExplorerActions.updateUploadOrDeleteResponse(data, childrenFiles, path)),
  dispatchDeleteResponse: (data, childrenFiles, path) => dispatch(FileExplorerActions.updateDeleteResponse(data, childrenFiles, path)),
  updateFileExplorerData: (data, provisioningMode) => dispatch(FileExplorerActions.updateFileExplorerData(data, provisioningMode)),
  resetRemoteScreenState: () => dispatch(RemoteScreenActions.reset()),
  uploadingCompleted: (fileName) => dispatch(FileExplorerActions.uploadingCompleted(fileName)),
  uploadingFailed: (fileName) => dispatch(FileExplorerActions.uploadingFailed(fileName)),
  endOfDownloading: () => dispatch(FileExplorerActions.endOfDownloading()),
  downloadingFailed: () => dispatch(FileExplorerActions.downloadingFailed())
})

export default pipe(
  connect(maspStateToProps, mapDispatchToProps)
)(RemoteScreen)
