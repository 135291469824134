import $ from 'jquery'
import app from './app'

import { COMMAND_RESUME } from './commands'

const DEVICE_BACKGROUND_COLOR = '#333'

export default app.device = (function () {
  const HEADER_HEIGHT = 0
  const FOOTER_HEIGHT = 85
  const MENU_BAR_HEIGHT = 24

  const PORTRAIT = 0
  const LANDSCAPE = 1

  let info
  let model = ''
  let ipAddress = ''
  const imagesRX = 0
  let context
  let contextLastImage
  let contextXorImage
  let $canvas
  let $canvasLastImage
  let $canvasXorImage
  let resizeWindowID
  let minimized = false
  let haveFullImage = false
  let firstFullImage = true
  const virtualScreen = {}
  let dialog = null

  // private methods
  function init () {
    initScreen()
    initCanvas()
    dialog = app.dialog
    // app.keyboard.handler()
  }

  function initScreen () {
    setScreenMin()
    resizeClientArea()

    $('.device').css('background-color', DEVICE_BACKGROUND_COLOR)

    const theme = 'ui-lightness'

    if (theme === 'ui-lightness') {
      $('.footer').addClass('ui-widget-content')
    } else if (theme === 'ui-lightness') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'humanity') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'base') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'redmond') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'sunny') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'overcast') {
      $('.footer').addClass('ui-state-default')
    } else if (theme === 'trontastic') {
      $('.footer').addClass('ui-widget-header')
    } else if (theme === 'blitzer') {
      // $(".footer").addClass("ui-state-default");
      // $(".footer").addClass("ui-widget-content");
      $('.footer').addClass('ui-widget-header')
    } else {
      $('.footer').addClass('ui-state-default')
    }

    if (theme !== 'trontastic') {
      $('.footer').css('background', DEVICE_BACKGROUND_COLOR)
      $('.footer').css('background-color', DEVICE_BACKGROUND_COLOR)
      $('.footer').css('border-color', DEVICE_BACKGROUND_COLOR)
    }

    // app.win.on("resize", resizeWindow);

    /* app.win.on("minimize", function() {
			minimized = true;
			console.log("Window is minimized");

			app.commands.send(COMMAND_SUSPEND, function(err){
				if(err){
					console.log("failed to send suspend command");
				}else{
					console.log("suspend command sent successfully");
				}
			});
		});		 */
  }

  function initCanvas () {
    $canvas = $('#canvas')
    context = $canvas[0].getContext('2d')
    context.canvas.width = 360
    // context.canvas.height = 550

    $canvasLastImage = $('#canvas-full')
    contextLastImage = $canvasLastImage[0].getContext('2d')
    contextLastImage.canvas.width = 0
    contextLastImage.canvas.height = 0

    $canvasXorImage = $('#canvas-xor')
    contextXorImage = $canvasXorImage[0].getContext('2d')
    contextXorImage.canvas.width = 0
    contextXorImage.canvas.height = 0

    // canvas event handlers
    context.canvas.addEventListener('mousedown', (event) => {
      app.mouse.down(event, context.canvas)
    }, false)
    context.canvas.addEventListener('mouseup', (event) => {
      app.mouse.up(event, context.canvas)
    }, false)
    context.canvas.addEventListener('mousemove', (event) => {
      app.mouse.move(event, context.canvas)
    }, false)
    context.canvas.addEventListener('mousewheel', (event) => {
      app.mouse.wheel(event, context.canvas)
    }, false)
  }

  function resizeWindow () {
    if (minimized) {
      minimized = false

      app.commands.send(COMMAND_RESUME, (err) => {

      })
    }

    if (resizeWindowID) {
      clearTimeout(resizeWindowID)
    }

    resizeWindowID = setTimeout(() => {
      // debounced resize
      clearTimeout(resizeWindowID)
      resizeWindowID = null

      resizeClientArea()

      setTimeout(() => {
        // final resize
        const dialogs = []
        const $dialog = $('div[role=dialog]')

        if ($dialog.length) {
          for (var ii = 0; ii < $dialog.length; ii++) {
            const dialog = $dialog[ii]
            const $dialogContent = $(dialog).find('.ui-dialog-content')
            const id = $dialogContent.attr('id')

            if (id === 'dialog-remote-input') {
              var $textarea = $(dialog).find('#dialog-remote-input').find('textarea')

              dialogs.push({
                id,
                val: $textarea.val()
              })
            } else if (id === 'dialog-command-buttons') {
              const buttons = app.dialog.getCommandButtonsFromForm(id)

              dialogs.push({
                id,
                buttons
              })
            }
          }

          closeAllDialogs()
          resizeClientArea()

          // resize dialogs
          for (ii = 0; ii < dialogs.length; ii++) {
            if (dialogs[ii].id === 'dialog-remote-input') {
              app.displayKeypad = true
              resizeClientArea()
              app.dialog.remoteInputForm()

              var $textarea = $('#dialog-remote-input').find('textarea')
              $textarea.val(dialogs[ii].val)
            } else if (dialogs[ii].id === 'dialog-command-buttons') {
              app.dialog.commands(dialogs[ii].buttons)
            }
          }
        } else {
          // no dialogs, final resize
          resizeClientArea()
        }
      }, 250)
    }, 250)
  }

  function sizeGridToWindow () { // Try stop invoking this
    if (app.displayKeypad) {
      sizeGridToWindowWithKeypad()
    } else {
      sizeGridToWindowWithoutKeypad() // Try commenting this.
    }
  }

  function isScreenSplitPortrait () {
    const width = (app.win.width + 1)
    const height = (app.win.height - MENU_BAR_HEIGHT)
    const xWidth = (width * 1.25)
    return height > xWidth
  }

  function sizeGridToWindowWithKeypad () {
    const $container = $('.keypad-container')
    var width = (app.win.width + 1)
    var height = (app.win.height - MENU_BAR_HEIGHT)
    const portrait = isScreenSplitPortrait()
    let footerHeight = FOOTER_HEIGHT
    const $fieldset = $('.footer').find('fieldset')

    if (!$container.length) {
      createKeypadContainer()
    }

    if ($fieldset.length) {
      footerHeight = parseInt($fieldset.css('height')) + 30
    }

    if (portrait) {
      var cols = width + 'px'

      if (HEADER_HEIGHT) {
        var row = (height - HEADER_HEIGHT - footerHeight) + 'px '
        var rows = HEADER_HEIGHT + 'px ' + row + row + footerHeight + 'px'
      } else {
        var row = ((height - footerHeight) / 2) + 'px '
        var rows = row + row + footerHeight + 'px'
      }
    } else {
      // landscape
      var cols = (width / 2) + 'px ' + (width / 2) + 'px'

      if (HEADER_HEIGHT) {
        var rows = HEADER_HEIGHT + 'px ' + (height - HEADER_HEIGHT - footerHeight) + 'px ' + footerHeight + 'px'
      } else {
        var rows = (height - footerHeight) + 'px ' + footerHeight + 'px'
      }
    }

    $('.grid-container').css({
      'grid-template-columns': cols,
      'grid-template-rows': rows
    })

    var row = 0
    let col
    var height
    var width
    let style

    if (HEADER_HEIGHT) {
      // header
      ++row
      col = 1
      height = 1	// in grid rows
      width = 2	// in grid columns

      style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
      $('.header').css(style)
    }

    // device
    ++row
    col = 1
    height = 1
    width = 1

    // style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
    // $('.device').css(style)

    // keypad
    if (portrait) {
      ++row
    } else {
      col = 2
    }
    style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
    $('.keypad-container').css(style)
    $('.keypad').show()

    // footer
    ++row
    col = 1
    height = 1
    width = 2

    style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
    $('.footer').css(style)
  }

  function sizeGridToWindowWithoutKeypad () {
    var width = (app.win.width + 1)
    var height = (app.win.height - MENU_BAR_HEIGHT)
    const cols = width + 'px'
    let footerHeight = FOOTER_HEIGHT
    const $fieldset = $('.footer').find('fieldset')

    if ($fieldset.length) {
      footerHeight = parseInt($fieldset.css('height')) + 30
    }

    if (HEADER_HEIGHT) {
      var rows = HEADER_HEIGHT + 'px ' + (height - HEADER_HEIGHT - footerHeight) + 'px ' + footerHeight + 'px'
    } else {
      var rows = (height - footerHeight) + 'px ' + footerHeight + 'px'
    }

    $('.grid-container').css({
      'grid-template-columns': cols,
      'grid-template-rows': rows
    })

    let row = 0
    let col
    var height
    var width
    let style

    if (HEADER_HEIGHT) {
      // header
      ++row
      col = 1
      height = 1	// in grid rows
      width = 1	// in grid columns
      style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }

      $('.header').css(style)
    }

    // device
    ++row
    col = 1
    height = 1
    width = 1

    // style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
    // $('.device').css(style)

    // footer
    ++row
    col = 1
    height = 1
    width = 1

    style = { 'grid-area': row + ' / ' + col + ' / span ' + height + ' / span ' + width }
    $('.footer').css(style)

    $('.keypad').hide()
  }

  // https://zhirzh.github.io/2017/09/02/mediarecorder/
  function recordVideo () {
    const chunks = []
    const recordTime = (10 * 1000)
    const ctx = app.device.getCanvasContext()
    const cStream = ctx.canvas.captureStream(30)
    const recorder = new MediaRecorder(cStream)

    recorder.ondataavailable = function (e) {
      chunks.push(e.data)
    }

    recorder.onstop = function () {
      // combine all our chunks in one blob
      const blob = new Blob(chunks)

      // do something with this blob
      const vidURL = URL.createObjectURL(blob)

      const vid = document.createElement('video')

      vid.controls = true
      vid.src = vidURL
      vid.onended = function () {
        URL.revokeObjectURL(vidURL)
      }
      document.body.insertBefore(vid, ctx.canvas)
    }

    recorder.start()
    // recorder.pause();
    // recorder.resume();

    setTimeout(() => {
      recorder.stop()
    }, recordTime)
  }

  // public methods
  function isPortrait (width, height) {
    if (height > width) {
      return true
    }
    return false
  }

  function sizeWindowToVirtualDisplay () {
    try {
      if (context && virtualScreen.width && virtualScreen.height) {
        let { width } = virtualScreen
        let { height } = virtualScreen

        if (context.canvas.width !== width) {
          context.canvas.width = width
        }

        if (context.canvas.height !== height) {
          context.canvas.height = height
        }

        const $footer = $('.footer')
        const footerHeight = parseInt($footer.css('height'))

        width += 20
        height = (height + footerHeight + MENU_BAR_HEIGHT + 60)

        if (app.win.width != width || app.win.height != height) {
          // app.win.resizeTo(width, height);
        }
      }
    } catch (e) {}
  }

  function fixBinary (bin) {
    const { length } = bin
    const buf = new ArrayBuffer(length)
    const arr = new Uint8Array(buf)
    for (let i = 0; i < length; i++) {
	      arr[i] = bin.charCodeAt(i)
    }
    return buf
  }

  function updateScreen (obj, callback) {
    // console.log('UpdateScreen > ', obj)
    if (obj && callback) {
      if (obj.width) {
        virtualScreen.width = obj.width
      }

      if (obj.height) {
        virtualScreen.height = obj.height
        // virtualScreen.height = 550
      }

      /* context.canvas.width = virtualScreen.width
      context.canvas.height = virtualScreen.height */

      // console.log(obj.buffer)
      const blob = new Blob([obj.buffer], { type: 'image/jpeg' })
      const url = window.URL.createObjectURL(blob)
      const img = new Image()

      // console.log(url);
      obj.full = true // With Recent changes in the device, it is always received a full image hence it is not required to do any XOR invocation.
      if (obj.full) {
        haveFullImage = false
        // dialog.closeDialog()

        // drawImage(img, url, callback)
        // Commented to verify.
        loadDeviceImage(img, url, context, () => { // Try passing callback as () => {} in here
          // save a copy

          loadDeviceImage(img, url, contextLastImage, callback)

          if (firstFullImage) {
            // console.log("first full image");
            firstFullImage = false

            // context.canvas.width = virtualScreen.width
            // context.canvas.height = virtualScreen.height

            contextLastImage.canvas.width = virtualScreen.width
            contextLastImage.canvas.height = virtualScreen.height

            contextXorImage.canvas.width = virtualScreen.width
            contextXorImage.canvas.height = virtualScreen.height

            setTimeout(() => {
              // resizeClientArea()
            }, 1000)

            // sizeWindowToVirtualDisplay()
          }
        })
      } else if (haveFullImage) {
        // xor image
        loadDeviceImage(img, url, contextXorImage, () => {
          xorImages(contextXorImage, contextLastImage, context)
          callback()
        })
      } else {
        callback()
      }
    }
  }

  function drawImage (image, url, callback) {
    image.src = url
    image.onload = function () {
      const canvas = document.getElementById('canvas-final')
      const canvasContext = canvas.getContext('2d')
      const wrh = image.width / image.height
      let newWidth = canvas.width
      let newHeight = newWidth / wrh
      if (newHeight > canvas.height) {
        newHeight = canvas.height
        newWidth = newHeight * wrh
      }
      const xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0
      const yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0

      canvasContext.drawImage(image, xOffset, yOffset, 360, newHeight)

      callback()
    }
  }

  function xorImages (srcContext, destContext, resultContext) {
    const timer = false
    const { width } = virtualScreen
    const { height } = virtualScreen

    /*  if (timer) {
      const start = app.timer.start()
    } */

    const srcBuff = srcContext.getImageData(0, 0, width, height).data
    const destBuff = destContext.getImageData(0, 0, width, height).data
    const xorImage = resultContext.getImageData(0, 0, width, height)
    const buf = xorImage.data

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const p = 4 * (width * y + x)
        buf[p] = srcBuff[p] ^ destBuff[p]
        buf[p + 1] = srcBuff[p + 1] ^ destBuff[p + 1]
        buf[p + 2] = srcBuff[p + 2] ^ destBuff[p + 2]
        buf[p + 3] = 255
      }
    }

    if (timer) {
    }

    // save new image
    destContext.putImageData(xorImage, 0, 0)

    // scale update virtual screen
    resultContext.drawImage(destContext.canvas, 0, 0, context.canvas.width, context.canvas.height)
  }

  function scaleVirtualScreen () {
    if (!context) {
      return
    }

    if (!app.screenScaling) {
      var percent = 1

      virtualScreen.scale = percent
      return percent
    }

    let width
    let height
    const clientAreaWidth = parseInt($('.device').css('width'))
    const clientAreaHeight = parseInt($('.device').css('height'))

    const portrait = isPortrait(virtualScreen.width, virtualScreen.height)

    if (portrait) {
      height = (clientAreaHeight * app.percentOfClientArea)
      percent = (height / virtualScreen.height)
      width = (virtualScreen.width * percent)

      if (width > (clientAreaWidth * app.percentOfClientArea)) {
        width = (clientAreaWidth * app.percentOfClientArea)
        percent = (width / virtualScreen.width)
        height = (virtualScreen.height * percent)
      }
    } else {
      // landscape
      width = (clientAreaWidth * app.percentOfClientArea)
      percent = (width / virtualScreen.width)
      height = (virtualScreen.height * percent)

      if (height > (clientAreaHeight * app.percentOfClientArea)) {
        height = (clientAreaHeight * app.percentOfClientArea)
        percent = (height / virtualScreen.height)
        width = (virtualScreen.width * percent)
      }
    }

    if (context.canvas.width !== Math.floor(width) || context.canvas.height !== Math.floor(height)) {
      context.canvas.width = width
      context.canvas.height = height
    }

    virtualScreen.scale = percent

    return percent
  }

  function loadDeviceImage (img, url, ctx, callback) {
    // console.log(url)
    img.onload = function () {
      if (ctx.canvas.id === 'canvas') {
        const percent = scaleVirtualScreen()
        const width = (img.width * percent)
        const height = (img.height * percent)

        // let clientHeight = document.getElementById('rv-area').clientHeight - 50
        ctx.canvas.width = width
        ctx.canvas.height = height

        // console.log('Came here')
        ctx.drawImage(this, 0, 0, width, height)
      } else {
        /* if (contextLastImage.canvas.width !== virtualScreen.width || contextLastImage.canvas.height !== virtualScreen.height) {
          contextLastImage.canvas.width = virtualScreen.width
          contextLastImage.canvas.height = virtualScreen.height
        }

        if (contextXorImage.canvas.width !== virtualScreen.width || contextXorImage.canvas.height !== virtualScreen.height) {
          contextXorImage.canvas.width = virtualScreen.width
          contextXorImage.canvas.height = virtualScreen.height
        } */
        // ctx.drawImage(this, 0, 0, img.width, img.height)
      }

      // URL.revokeObjectURL(url);

      callback()
    }
    img.src = url
    /* if(gettingImage%2==0){
			try{
			img.src = url;
			}catch(err){
				img.src = url;
			}
		} */

    // console.log(url)
    gettingImage++
    const dt = new Date()
    // console.log(gettingImage+' '+dt+''+dt.getMilliseconds())
  }

  var gettingImage = 0

  function connect (device, callback) {
    if (callback && app.nodeWebkit) {
      callback()
    }
  }

  function disconnected () {
    const shutdownOnDisconnect = true

    if (shutdownOnDisconnect) {
      let id
      let $dialog
      let $message
      let seconds = 5
      const str = 'Android device has disconnected, shutting down in ' + seconds + '...'

      app.dialog.error(str, () => {
        // user closed dialog
        clearInterval(id)
        appShutdown()
      })

      id = setInterval(() => {
        if (!$dialog) {
          $dialog = $('#dialog-error')
          $message = $dialog.find('.dialog-message')
        }
        if ($dialog) {
          if (--seconds > 0) {
            $message.text('Android device has disconnected, shutting down in ' + seconds + '...')
          } else {
            // countdown expired
            clearInterval(id)
            appShutdown()
          }
        }
      }, 1000)
    }/*  else {
      setWindowTitle('Not Connected')

      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      context.canvas.width = 0
      context.canvas.height = 0

      contextLastImage.clearRect(0, 0, contextLastImage.canvas.width, contextLastImage.canvas.height)
      contextLastImage.canvas.width = 0
      contextLastImage.canvas.height = 0

      contextXorImage.clearRect(0, 0, contextXorImage.canvas.width, contextXorImage.canvas.height)
      contextXorImage.canvas.width = 0
      contextXorImage.canvas.height = 0
    } */
  }

  function setWindowTitle (str) {
    if (app.debug && app.adb) {
      // document.title = "Remote ADB Device: " + str;
    } else {
      // document.title = "Remote Device: " + str;
    }
  }

  function getCanvasContext () {
    return contextLastImage
  }

  function getCanvasContextXOR () {
    return contextXorImage
  }

  function getVirtualScreen () {
    return virtualScreen
  }

  function resizeClientArea () {
    sizeGridToWindow() // Try commenting this
    scaleVirtualScreen()
  }

  function appShutdown () {
    if (!app.debug && !app.adb) {
      app.server.stop()
    }
    if (app.saveWindowPosition) {
      app.windowState.save()
    }
    app.gui.App.quit()
  }

  function createKeypadContainer () {
    $('.keypad-container').remove()

    const html = '<div class="item keypad-container">' +
						'<div class="keypad"></div>' +
					'</div>'

    $(html).insertAfter('#device-container')

    app.displayKeypad = true
    resizeClientArea()
  }

  function isOverflow (element) {
    if (element) {
      return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
    }
  }

  function setInfo (obj) {
    info = obj
  }

  function getInfo () {
    return info
  }

  function setModel (str) {
    model = str
  }

  function getModel () {
    return model
  }

  function setAddress (str) {
    ipAddress = str
  }

  function getAddress () {
    return ipAddress
  }

  function setScreenMin () {
    const minWidth = 350
    const minHeight = 400

    // app.win.setMinimumSize(minWidth, minHeight);
  }

  function closeAllDialogs () {
    const $closeButton = $('div[role=dialog]').find('button[title=Close]')
    $closeButton.click()
  }

  // public API
  return {
    init,
    connect,
    setInfo,
    getInfo,
    setModel,
    getModel,
    isOverflow,
    setAddress,
    getAddress,
    appShutdown,
    recordVideo,
    updateScreen,
    disconnected,
    setWindowTitle,
    closeAllDialogs,
    resizeClientArea,
    getVirtualScreen,
    getCanvasContext,
    getCanvasContextXOR,
    isScreenSplitPortrait,
    createKeypadContainer
  }
}())
