import { createSelector, createStructuredSelector } from 'reselect'
import { IPSM_PATH, INTERNAL_STORAGE } from 'Utils/constants'

const folderData = ({ fileExplorer }) => fileExplorer.getIn(['data'], [])
const activeFolderPath = ({ fileExplorer }) => fileExplorer.getIn(['activeFolderDetails', 'path'], '')

const folderStructure = createSelector(
  [folderData],
  (data) => {
    return data.filter(node => (node.path))
  }
)

export default createStructuredSelector({
  folderStructure,
  activeFolderPath
})
