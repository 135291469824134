import React, { useState, useCallback } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { pipe } from 'ramda'
import { connect } from 'react-redux'

import { StyledToastContainer } from 'Components/Toasts'
import 'react-toastify/dist/ReactToastify.css'
import { deviceId } from '../../Utils/device-helpers'
import { isMobile } from '../../Utils/constants'
import mapStateToProps from './app-frame.selector'
import RemoteScreen from '../../Features/RemoteScreen'
import FileExplorer from '../../Features/FileExplorer'

import {
  AppFrameContainer,
  BodyWrapper,
  StyledHeader,
  StyledFooter,
  ContentWrapper,
  ToggleFileExplorerSwitch,
  DeviceNameWrapper,
  ToggleWrapper,
  StyledLabel
} from './app-frame.styles'

const darkTheme = { black: '#000 !important', background: '#202020 !important', headerborder: '#606060 0.0625rem solid !important', backgroundheader: '#000', bodyfontcolor: '#fff !important', h1color: '#fff !important', h2color: '#f7f7f7 !important', h4color: '#c0c0c0', headercolor: '#fff', headerhover: '#202020 !important', logotextcolor: '#fff', sidebarback: '#000', sidebarcolor: '#fff', sidebarborder: '#404040', sidebaractive: '#303030', iconscolor: '#fff !important', sidebaricons: 'red', sidebarcoloractive: 'red', sideactiveitem: '#303030', sideactivehover: '#202020 !important', inputborder: '#707070 0.0625rem solid', inputback: '#303030 !important', inputcolor: '#e0e0e0 !important;', inputcoloractive: '#fff !important', dashboardcard: '#303030', textcontrast: '#c0c0c0 !important', inputbordercolor: '#707070', grayback: '#303030 !important', intercolor: '#707070', inactivecolor: '#606060', tabcolor: '#d0d0d0', hovercolor: '#f7f7f7', secondarycolor: '#f0f0f0', tagbackground: '#606060 !important', logomain: 'url(/static/media/honeywell-logo-white.97d89001.svg)', boxShadow: '0px 1px 0px #404040', darkBorder: '1px solid #404040' }
const lightTheme = {}
const show = {
  display: 'block'
}
const hide = {
  display: 'none'
}
const BetaTitle = (
  <React.Fragment>
    <label> Op. Intel </label>
    {/* <Label>(ßeta) </Label> */}
  </React.Fragment>
)

const AppFrame = ({
  dataChannelStatus
}) => {
  const [toggleFileExplorer, setToggleFileExplorer] = useState(false)

  const handleToggleSwitch = useCallback(() => {
    setToggleFileExplorer((preValue) => !preValue)
  }, [setToggleFileExplorer])

  return (
    <AppFrameContainer>
      <ThemeProvider theme={lightTheme}>
        <StyledToastContainer
          position='top-right'
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={false}
          closeButton={false}
          draggable={false}
          rtl={false}
          enableMultiContainer={true}
          toastClassName='toast-notification-wrap'
        />
        <BodyWrapper>
          <StyledHeader
            title={isMobile() ? BetaTitle : 'Operational Intelligence - Remote Control'} menu={false}
          >
            <DeviceNameWrapper>{`DEVICE: ${deviceId}`}</DeviceNameWrapper>
            <ToggleFileExplorerSwitch>
              <label className='switch'>
                <input type='checkbox' onChange={handleToggleSwitch} />
                <span className='slider round' />
              </label>
              <StyledLabel>File Explorer</StyledLabel>
            </ToggleFileExplorerSwitch>
          </StyledHeader>
          <ContentWrapper>
            <ToggleWrapper style={!toggleFileExplorer ? show : hide}> <RemoteScreen /> </ToggleWrapper>
            <ToggleWrapper style={toggleFileExplorer ? show : hide}> <FileExplorer /> </ToggleWrapper>
          </ContentWrapper>
          <StyledFooter copyrightText='Copyright &#169; 2020 Honeywell International Inc' />
        </BodyWrapper>
      </ThemeProvider>
    </AppFrameContainer>
  )
}

export default pipe(
  connect(mapStateToProps, null)
)(AppFrame)

// export default AppFrame
