import app from './app'

export default app.timer = function(){
	"use strict";

	// public methods
	function start(){
		return new Date().getTime();
	}
	
	function elapsed(start){
		var now = new Date().getTime();
		return (now - start);
	}
	
	// public API
	return {start: start,
			elapsed: elapsed};
}();
