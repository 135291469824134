import * as hwColors from './honeywell-colors'

export interface ThemeColorScheme {
  // core colors
  primary: string;
  secondary: string;
  success: string;
  alert: string;
  error: string;
  critical: string;
  important: string; // HUE council agreed value
  info: string;
  // Chrome
  chromeBackground: string;
  chromeActiveHighlight: string;
  chromeHover: string;
  chromeActive: string;
  chromeSubmenuBackground: string;
  // Content
  contentBackground: string;
  componentBackground: string;
  invertedComponentBackground: string;
  loaderBackground: string;
  shadow: string;
  // primary color alternations
  primaryHover: string;
  primaryActive: string;
  primaryDisabled: string;
  // secondary color alternations
  secondaryHover: string;
  secondaryActive: string;
  secondaryDisabled: string;
  // bBackground hover
  backgroundHover: string;
  // Text
  textChrome: string;
  textBase: string;
  textHighContrast: string;
  textMediumContrast: string;
  textLowContrast: string;
  textFixed: string;
  // Text Inverted
  textInvertedBase: string;
  textInvertedHighContrast: string;
  textInvertedMediumContrast: string;
  textInvertedLowContrast: string;
  // Icons
  iconDisabled: string;
  iconHover: string;
  iconActive: string;
  // Input
  inputBase: string;
  inputBorder: string;
  inputIcon: string;
  inputDisabled: string;
  inputDisabledText: string;
  // Dividers
  dividerColor: string;
  strokeInteractive: string;
  // Buttons
  buttonPrimary: string;
  buttonPrimaryHover: string;
  buttonPrimaryFocus: string;
  buttonTextColor: string;
  buttonDisabled: string;
  buttonDisabledTextColor: string;
  // inline
  buttonInline: string;
  buttonInlineHover: string;
  buttonInlineFocus: string;
  buttonInlineTextColor: string;
  buttonInlineDisabled: string;
  buttonInlineDisabledTextColor: string;
}

export const light: ThemeColorScheme = {
  // core colors
  primary: hwColors.hwColorBlue20k,
  secondary: hwColors.hwColorGrey85,
  success: hwColors.hwColorGreen,
  alert: hwColors.hwColorOrange,
  error: hwColors.hwColorRed20k,
  critical: hwColors.hwColorRed80,
  important: hwColors.hwColorYellow, // HUE council agreed value
  info: hwColors.hwColorBlue,
  // Chrome
  chromeBackground: hwColors.hwColorWhite,
  chromeActiveHighlight: hwColors.hwColorBlue,
  chromeHover: hwColors.hwColorGrey10,
  chromeActive: hwColors.hwColorGrey10,
  chromeSubmenuBackground: hwColors.hwColorWhiteRich,
  // Content
  contentBackground: hwColors.hwColorWhiteRich,
  componentBackground: hwColors.hwColorWhite,
  invertedComponentBackground: hwColors.hwColorBlackChrome,
  loaderBackground: hwColors.hwColorWhiteLyric,
  shadow: hwColors.hwColorShadow,
  // primary color alternations
  primaryHover: hwColors.hwColorBlue30k,
  primaryActive: hwColors.hwColorBlue20k,
  primaryDisabled: hwColors.hwColorGrey30,
  // secondary color alternations
  secondaryHover: hwColors.hwColorWhiteLyric,
  secondaryActive: hwColors.hwColorWhiteLyric,
  secondaryDisabled: hwColors.hwColorGrey30,
  // bBackground hover
  backgroundHover: hwColors.hwColorBlue20,
  // Text
  textChrome: hwColors.hwColorGrey85,
  textBase: hwColors.hwColorGrey85,
  textHighContrast: hwColors.hwColorGrey80,
  textMediumContrast: hwColors.hwColorGrey70,
  textLowContrast: hwColors.hwColorGrey40,
  textFixed: hwColors.hwColorWhite,
  // Text Inverted
  textInvertedBase: hwColors.hwColorWhite,
  textInvertedHighContrast: hwColors.hwColorGrey10,
  textInvertedMediumContrast: hwColors.hwColorGrey25,
  textInvertedLowContrast: hwColors.hwColorGrey70,
  // Icons
  iconDisabled: hwColors.hwColorGrey30,
  iconHover: hwColors.hwColorGrey80,
  iconActive: hwColors.hwColorGrey85,
  // Input
  inputBase: hwColors.hwColorWhite,
  inputBorder: hwColors.hwColorGrey20,
  inputIcon: hwColors.hwColorGrey30,
  inputDisabled: hwColors.hwColorWhiteRich,
  inputDisabledText: hwColors.hwColorGrey25,
  // Dividers
  dividerColor: hwColors.hwColorGrey20,
  strokeInteractive: hwColors.hwColorGrey20,
  // Buttons
  buttonPrimary: hwColors.hwColorBlue20k,
  buttonPrimaryHover: hwColors.hwColorBlue30k,
  buttonPrimaryFocus: hwColors.hwColorBlue50k,
  buttonTextColor: hwColors.hwColorWhite,
  buttonDisabled: hwColors.hwColorGrey40,
  buttonDisabledTextColor: hwColors.hwColorWhite,
  // inline
  buttonInline: hwColors.hwColorGrey85,
  buttonInlineHover: hwColors.hwColorGrey80,
  buttonInlineFocus: hwColors.hwColorGrey60,
  buttonInlineTextColor: hwColors.hwColorWhite,
  buttonInlineDisabled: hwColors.hwColorGrey40,
  buttonInlineDisabledTextColor: hwColors.hwColorWhite
}

export const dark: ThemeColorScheme = {
  // core colors
  primary: hwColors.hwColorBlue80,
  secondary: hwColors.hwColorGrey10,
  success: hwColors.hwColorGreen,
  alert: hwColors.hwColorOrange,
  error: hwColors.hwColorRed80,
  critical: hwColors.hwColorRed80,
  important: hwColors.hwColorYellow, // HUE council agreed value
  info: hwColors.hwColorBlue,
  // Chrome
  chromeBackground: hwColors.hwColorBlack,
  chromeActiveHighlight: hwColors.hwColorBlue80,
  chromeHover: hwColors.hwColorGrey80,
  chromeActive: hwColors.hwColorGrey80,
  chromeSubmenuBackground: hwColors.hwColorBlackChrome,
  // Content
  contentBackground: hwColors.hwContentBlack,
  componentBackground: hwColors.hwColorBlackChrome,
  invertedComponentBackground: hwColors.hwColorGrey10,
  loaderBackground: hwColors.hwColorGrey80,
  shadow: hwColors.hwColorShadow2,
  // primary color alternations
  primaryHover: hwColors.hwColorBlue80,
  primaryActive: hwColors.hwColorBlue50,
  primaryDisabled: hwColors.hwColorGrey70,
  // secondary color alternations
  secondaryHover: hwColors.hwColorGrey80,
  secondaryActive: hwColors.hwColorGrey80,
  secondaryDisabled: hwColors.hwColorGrey75,
  // bBackground hover
  backgroundHover: hwColors.hwColorGrey80,
  // New Text
  textChrome: hwColors.hwColorWhite,
  textBase: hwColors.hwColorGrey10,
  textHighContrast: hwColors.hwColorGrey25,
  textMediumContrast: hwColors.hwColorGrey40,
  textLowContrast: hwColors.hwColorGrey70,
  textFixed: hwColors.hwColorWhite,
  // Text Inverted
  textInvertedBase: hwColors.hwColorBlack,
  textInvertedHighContrast: hwColors.hwColorGrey85,
  textInvertedMediumContrast: hwColors.hwColorGrey25,
  textInvertedLowContrast: hwColors.hwColorGrey30,
  // Icons
  iconDisabled: hwColors.hwColorGrey30,
  iconHover: hwColors.hwColorGrey20,
  iconActive: hwColors.hwColorWhite,
  // Input
  inputBase: hwColors.hwColorBlackChrome,
  inputDisabled: hwColors.hwColorGrey75,
  inputDisabledText: hwColors.hwColorGrey40,
  inputBorder: hwColors.hwColorGrey60,
  inputIcon: hwColors.hwColorGrey10,
  // Dividers
  dividerColor: hwColors.hwColorGrey70,
  strokeInteractive: hwColors.hwColorGrey60,
  // Buttons
  buttonPrimary: hwColors.hwColorBlue80,
  buttonPrimaryHover: hwColors.hwColorBlue70,
  buttonPrimaryFocus: hwColors.hwColorBlue50,
  buttonTextColor: hwColors.hwColorBlack,
  buttonDisabled: hwColors.hwColorGrey75,
  buttonDisabledTextColor: hwColors.hwColorBlack,
  // inline
  buttonInline: hwColors.hwColorGrey10,
  buttonInlineHover: hwColors.hwColorGrey20,
  buttonInlineFocus: hwColors.hwColorGrey30,
  buttonInlineTextColor: hwColors.hwColorBlack,
  buttonInlineDisabled: hwColors.hwColorGrey75,
  buttonInlineDisabledTextColor: hwColors.hwColorBlack
}
