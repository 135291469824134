import { pipe } from 'ramda'
import { connect } from 'react-redux'

import { FileExplorerActions } from '../store'
import FileExplorer from './file-explorer'
import maspStateToProps from './file-explorer.selector'

const mapDispatchToProps = dispatch => ({
  setFileExplorerLoading: (loading) => dispatch(FileExplorerActions.updateFileExplorerLoading(loading))
  // uploadFile: (file) => dispatch(FileExplorerActions.uploadFile(file))
})

export default pipe(
  connect(maspStateToProps, mapDispatchToProps)
)(FileExplorer)
