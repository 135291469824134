import styled from 'styled-components/macro'

import { Icon } from '@scuf/common'

export const StyledProgressContainer = styled.div`
  margin-top: auto;
  display: flex;
  background: #FFF;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  margin-bottom: .5rem;
  border: 1px solid;
  border-color: ${p => p.failedStatus ? '#BE271C' : '#1274B7'};
`

export const StyledProgressCol1 = styled.div`
  display: flex;
  column-rule: 1px solid #d0d0d0;
  width: ${p => (p.percent === 100 || p.failedStatus ? '100%' : '60%')};
  max-width: ${p => (p.percent === 100 || p.failedStatus ? '100%' : '60%')};
`
export const StyledProgressCol2 = styled.div`
  width: 40%;
  display: flex;
  gap: 1rem;
`
export const StyledProgressBoldLabel = styled.div`
  font-weight: bold;
`
export const StyledIconClose = styled(Icon)`
  order: 5;
  color: #303030 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  padding-top: .25rem !important;
  margin-right: .5rem;
  margin-left: auto;
  cursor: pointer;
`
export const StyledIconWarning = styled(Icon)`
  color: #BE271C !important;
  padding-top: .25rem !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-right: .5rem;
`
export const StyledIconCheck = styled(Icon)`
  color: #1274B7 !important;
  padding-top: .25rem !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-right: .5rem;
`
export const StyledProgressNamelabel = styled.div`
  max-width: 60%;
  text-overflow: ellipsis;
  overflow-x: hidden;  
  white-space: nowrap;
  cursor: default;
}
`
export const StyledFailedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`
export const StyledFailedText = styled.div``

export const StyledDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #BE271C;
  border-radius: 50%;
  margin-right: 4px;
`

export const StyledProgressBarWrapper = styled.div`
  width: 100%;
  border: 1px solid #CCC !important;
  height: .75rem;
  align-self: center;
`
export const StyledPercent = styled.div`
  color: #000!important;
  background-color: #1792E5 !important;
  height: 100%;
  width: 0%;
  max-width: 100%;
`
