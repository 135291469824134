import * as React from 'react'
import { darkCompactTheme, lightCompactTheme } from './themes'
import { ThemeType, toggleTheme } from '@scuf/common'
import { ThemeProvider } from 'styled-components/macro'
import { useSelector } from 'react-redux'

export const ConnectedThemeProvider: React.FC = ({ children }) => {
  //const storeTheme = useSelector(({ user }) => (user as any).getIn(['theme'], {}))
  const storeTheme = {}
  const themeMode = React.useMemo(
    () => Object.keys(storeTheme).length ? 'dark' : 'light',
    [storeTheme]
  )

  React.useEffect(() => {
    themeMode === 'dark'
      ? toggleTheme(ThemeType.Dark)
      : toggleTheme(ThemeType.Light)
  }, [themeMode])

  const theme = themeMode === 'dark' ? darkCompactTheme : lightCompactTheme

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
