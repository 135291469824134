import app from './app'

export default app.log = (function(){
	"use strict";

	const LOG_FILE = "log.txt";
	const DEBUG_LOGGING = false;

	var file;

	// private methods
	function writeToLogFile(str){
		if(app.fs){
			if(!file){
				deleteFile();
			}
			if(file){
				if(!app.fs.existsSync(file)){
					app.fs.writeFileSync(file, str);
				}else{
					app.fs.appendFileSync(file, str);
				}
			}
		}
	}
	
	function deleteFile(){
		var dir;
		
		if(DEBUG_LOGGING){
			dir = "c:/gitrepo/SSApps/Applications/SmartSystemsTools/AndroidRemoteControl/www";
		}else{
			dir = process.cwd().replace(/\\/g, "/");
		}
		
		file = dir + "/" + LOG_FILE;
		
		if(app.fs && app.fs.existsSync(file)){
			app.fs.unlinkSync(file);
		}
	}

	// public methods
	function info(str){
		if(str && str.length){
			str += "\r\n";
			
			if(app.debug){
			}else{
				if(app.nodeWebkit){
					writeToLogFile("I: [" + new Date().toISOString() + "] " + str);
				}
			}
		}
	}
	
	function warning(str){
		if(str && str.length){
			str += "\r\n";
			
			if(app.debug){
			}else{
				if(app.nodeWebkit){
					writeToLogFile("W: [" + new Date().toISOString() + "] " + str);
				}
			}
		}
	}
	
	function error(str){
		if(str && str.length){
			str += "\r\n";
			
			if(app.debug){
			}else{
				if(app.nodeWebkit){
					writeToLogFile("E: [" + new Date().toISOString() + "] " + str);
				}
			}
		}
	}
	
	// public API
	return {info: info,
			error: error,
			warning: warning,
			deleteFile: deleteFile};
}());
