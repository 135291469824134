import React from 'react'
import { Button } from '@scuf/common'
import {
  StyledModal
} from './delet-modal.styles'

const DeleteModal = ({
  openModal,
  handleCancel,
  handleDelete
}) => {
  return (
    <StyledModal open={openModal} closeIcon={true} onClose={handleCancel} size='small' closeOnDimmerClick={false}>
      <StyledModal.Header>
        Delete File
      </StyledModal.Header>
      <StyledModal.Content>
        This folder or file will be deleted from the device, do you want to proceed?
      </StyledModal.Content>
      <StyledModal.Footer>
        <Button type='secondary' size='medium' content='NO' onClick={handleCancel} />
        <Button type='primary' content='YES' size='medium' onClick={handleDelete} />
      </StyledModal.Footer>
    </StyledModal>
  )
}

export default DeleteModal
