import React, { useState, useEffect, useCallback } from 'react'
import { AiOutlineFile, AiOutlineFolder, AiOutlineMinus, AiOutlinePlus, AiOutlineFileText, AiOutlineFileZip, AiOutlineFileJpg, AiOutlinePlayCircle, AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileExcel, AiOutlineFileImage, AiOutlineAndroid } from 'react-icons/ai'
import { DiJavascript1, DiCss3Full, DiHtml5, DiReact, DiJava } from 'react-icons/di'
import { v4 } from 'uuid'

import Popup from '../Popup'
import DisplayName from './display-name'
import { IPSM_PATH, INTERNAL_STORAGE } from 'Utils/constants'
import {
  FolderTreeWrapper,
  FolderTreeContainer,
  StyledFile,
  StyledFolder,
  FolderWrapper,
  FolderLabel,
  FolderNameWrapper,
  Collapsible
} from './folder-tree.styles'

import structure from './folder-tree-new'

const FILE_ICONS = {
  js: <DiJavascript1 />,
  css: <DiCss3Full />,
  html: <DiHtml5 />,
  jsx: <DiReact />,
  java: <DiJava />,
  apk: <AiOutlineAndroid />,
  txt: <AiOutlineFileText />,
  zip: <AiOutlineFileZip />,
  jpg: <AiOutlineFileJpg />,
  png: <AiOutlineFileImage />,
  mp4: <AiOutlinePlayCircle />,
  pdf: <AiOutlineFilePdf />,
  docx: <AiOutlineFileWord />,
  xlsx: <AiOutlineFileExcel />
}

const File = ({ name, path }) => {
  const [showPopupOption, setShowPopupOption] = useState(false)
  const [isdeleteModalActive, setDeleteModalActive] = useState(false)
  const ext = name.split('.')[1]

  return (
    <StyledFile
      onMouseEnter={() => !isdeleteModalActive && setShowPopupOption(true)}
      onMouseLeave={() => !isdeleteModalActive && setShowPopupOption(false)}
    >
      {FILE_ICONS[ext] || <AiOutlineFile />}
      <DisplayName name={name} />
      {showPopupOption ? <Popup fileName={name} path={path} setDeleteModalActive={setDeleteModalActive} /> : null}
    </StyledFile>
  )
}

const Folder = ({ folderDetails, updateSelectedFolder, activeFolderPath, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showPopupOption, setShowPopupOption] = useState(false)
  const [isdeleteModalActive, setDeleteModalActive] = useState(false)
  const { name, path } = folderDetails

  useEffect(() => {
    if (path === activeFolderPath) setIsOpen(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const handleSelectedFolder = useCallback(() => {
    setIsOpen(true)
    updateSelectedFolder(folderDetails)
  }, [folderDetails, setIsOpen, updateSelectedFolder])

  return (
    <StyledFolder>
      <FolderWrapper>
        <FolderLabel
          onMouseEnter={() => !isdeleteModalActive && setShowPopupOption(true)}
          onMouseLeave={() => !isdeleteModalActive && setShowPopupOption(false)}
        >
          {!isOpen
            ? <AiOutlinePlus onClick={handleToggle} />
            : <AiOutlineMinus onClick={handleToggle} />}
          <FolderNameWrapper onClick={handleSelectedFolder}>
            <AiOutlineFolder />
            <DisplayName name={name} />
          </FolderNameWrapper>
          {showPopupOption && path !== INTERNAL_STORAGE && path !== IPSM_PATH 
            ? <Popup fileName={name} path={path} setDeleteModalActive={setDeleteModalActive} />
            : null}
        </FolderLabel>
        <Collapsible isOpen={isOpen}>{children}</Collapsible>
      </FolderWrapper>
    </StyledFolder>
  )
}

const TreeRecursive = ({ data, updateSelectedFolder, activeFolderPath }) => {
  return data.map(item => {
    const id = v4()
    if (item.type === 'file') {
      return <Tree.File name={item.name} key={id} path={item.path} />
    }
    if (item.type === 'folder') {
      const folderDetails = {
        id,
        name: item.name,
        path: item.path,
        childrenFiles: [...item.children || []]
      }
      return (
        <Tree.Folder key={item.path} folderDetails={folderDetails} updateSelectedFolder={updateSelectedFolder} activeFolderPath={activeFolderPath}>
          <Tree.Recursive data={item.children || []} updateSelectedFolder={updateSelectedFolder} activeFolderPath={activeFolderPath} />
        </Tree.Folder>
      )
    }

    return null
  })
}

const Tree = ({ folderStructure, activeFolderPath, updateSelectedFolder }) => {
  return (
    <FolderTreeWrapper>
      <FolderTreeContainer>
        <Tree.Recursive data={folderStructure || []} updateSelectedFolder={updateSelectedFolder} activeFolderPath={activeFolderPath} />
      </FolderTreeContainer>
    </FolderTreeWrapper>
  )
}

Tree.Recursive = React.memo(TreeRecursive)
Tree.File = React.memo(File)
Tree.Folder = React.memo(Folder)

export default React.memo(Tree)
