export const spacingBase3xs = '0.125rem'
export const spacingBase2xs = '0.125rem'
export const spacingBaseXs = '0.25rem'
export const spacingBaseSm = '0.5rem'
export const spacingBaseMd = '0.75rem'
export const spacingBaseLg = '1rem'
export const spacingBaseXl = '1.5rem'
export const spacingBase2xl = '2rem'
export const spacingBase3xl = '2.5rem'
export const spacingBase4xl = '3rem'
export const borderRadius = '0rem'
export const borderWidth = '0.0625rem'
export const iconSm = '1rem'
export const iconMd = '1.5rem'
