import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { RemoteScreenTypes } from './actions'

export const INITIAL_STATE = Immutable({
  deviceId: null,
  dataChannelStatus: false
})

const updateDeviceId = (state, { deviceId }) => state.merge({ deviceId })
const updateDataChannelStatus = (state, { status }) => state.merge({ dataChannelStatus: status })
const reset = () => INITIAL_STATE

export const remoteScreenReducer = createReducer(INITIAL_STATE, {
  [RemoteScreenTypes.UPDATE_DEVICE_ID]: updateDeviceId,
  [RemoteScreenTypes.UPDATE_DATA_CHANNEL_STATUS]: updateDataChannelStatus,
  [RemoteScreenTypes.RESET]: reset
})
