import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  updateFileExplorerLoading: ['loading'],
  updateDeviceManufacturerInfo: ['deviceManufacturerInfo'],
  updateFileExplorerAvailability: ['isFileExplorerAvailable', 'agentVersion'],
  uploadFile: ['selectedFile'],
  fileName: ['fileName'],
  updateSelectedFolder: ['folderDetails'],
  updateUploadOrDeleteResponse: ['data', 'childrenFiles', 'path'],
  updateDeleteResponse: ['data', 'childrenFiles', 'path'],
  updateDeletingDetails: ['actualPath', 'fileName'],
  updateFileExplorerData: ['data', 'provisioningMode'],
  updateChildrensData: ['childrens'],
  startUploading: ['fileName', 'inProgress'],
  uploadingCompleted: ['fileName'],
  uploadingFailed: ['fileName'],
  resetCurrentUploadingDetails: null,
  insertUploadWaitingResponse: ['fileName'],
  removeUploadWaitingResponse: ['fileName'],
  startDownloading: ['fileName', 'inProgress'],
  downloadingCompleted: null,
  downloadingFailed: null,
  updateDownloadingPercentage: ['downloadPercentage'],
  resetCurrentDownloadingDetails: null,
  endOfDownloading: null
}, {
  prefix: '@FILE-EXPLORER/'
})

export const FileExplorerTypes = Types
export const FileExplorerActions = Creators
