import { getQueryParams } from 'Utils/device-helpers'
import { str2ab } from 'Utils/file-helpers'
import {
  DATACHANNEL_FILE_EXPLORER_TOGGLE,
  DATACHANNEL_FILE_TYPE,
  DATACHANNEL_FILE_DOWNLOAD_COMMAND,
  DATACHANNEL_FILE_UPLOAD_COMMAND,
  DATACHANNEL_FILE_DELETE_COMMAND,
  DATACHANNEL_FILE_DATA_COMMAND,
  DATACHANNEL_PROVISIONING_MODE_ENABLED,
  DATACHANNEL_PROVISIONING_MODE_DISABLED
} from 'Utils/constants'
import {
  logEvent,
  LOG_FE_FILEDELETE_STARTED,
  LOG_FE_FETCH_FILEDATA_STARTED,
  LOG_FE_FILEDOWNLOAD_STARTED,
  LOG_RV_WEBRTC_DATACHANNEL_OPEN,
  LOG_RV_WEBRTC_DATACHANNEL_CLOSED,
  LOG_RV_WEBRTC_DATACHANNEL_MESSAGE_60SECONDS_TIMEOUT
} from 'Utils/log-helper'

const deviceId = getQueryParams('deviceId', window.location.href) || ''
let dataChannelWaitingTimeout
const connection = { optional: [{ DtlsSrtpKeyAgreement: true }] }
let dataChannelMsgReceived = false
let peerConnection = null
let dataChannel = null

export function createWebRTCConnection (iceConfiguration, sendNegotiation, { succesDataChannelOpenCallback, successDataChannelCallback, handelErrorCondition }) {
  const sdpConstraints = {
    offerToReceiveAudio: false,
    offerToReceiveVideo: false,
    iceRestart: true
  }

  dataChannelWaitingTimeout = setTimeout(() => {
    if (!dataChannelMsgReceived) {
      logEvent(LOG_RV_WEBRTC_DATACHANNEL_MESSAGE_60SECONDS_TIMEOUT)
      closePeerConnection()
      handelErrorCondition()
    }
  }, 60000)

  peerConnection = new RTCPeerConnection(iceConfiguration, connection)

  peerConnection.onicecandidate = function (e) {
    if (!peerConnection || !e || !e.candidate) return
    const { candidate } = e
    sendNegotiation('candidate', candidate)
  }

  peerConnection.oniceconnectionstatechange = function (event) {
    if (peerConnection.iceConnectionState === 'closed') {
      closePeerConnection()
      handelErrorCondition()
    }
  }

  dataChannel = peerConnection.createDataChannel(deviceId, { reliable: true })

  dataChannel.onopen = function () {
    logEvent(LOG_RV_WEBRTC_DATACHANNEL_OPEN)
    succesDataChannelOpenCallback()
  }

  dataChannel.onclose = function () {
    logEvent(LOG_RV_WEBRTC_DATACHANNEL_CLOSED)
    closePeerConnection()
    handelErrorCondition()
  }
  dataChannel.onerror = function () { }

  dataChannel.onmessage = function (msg) {
  }

  peerConnection.ondatachannel = function (ev) {
    ev.channel.onopen = function () { }

    ev.channel.onmessage = function (e) {
      try {
        dataChannelMsgReceived = true
        clearTimeout(dataChannelWaitingTimeout)

        const data = JSON.parse(e.data)
        successDataChannelCallback(data)
      } catch (err) {}
    }

    ev.channel.onerror = function (errorDetails) {}
  }

  peerConnection.createOffer(sdpConstraints).then((sdp) => {
    peerConnection.setLocalDescription(sdp)
    sendNegotiation('offer', sdp)
  }, () => {})

  return peerConnection
}

export function processAnswer (answer) {
  peerConnection.setRemoteDescription(new RTCSessionDescription(answer))
  return true
}

export function processIce (iceCandidate) {
  peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidate)).catch(e => {})
}

export function closePeerConnection () {
  dataChannel && dataChannel.close()
  peerConnection && peerConnection.close()
}

export function checkBufferedLowBrowserSupport () {
  if (dataChannel) return typeof dataChannel.bufferedAmountLowThreshold === 'number'
  return false
}

export function setBufferLowThreshold (bufferFullThreshold) {
  dataChannel.bufferedAmountLowThreshold = bufferFullThreshold
}

export function isBufferedAmountAboveThreshold (bufferFullThreshold) {
  if (dataChannel) return dataChannel.bufferedAmount > bufferFullThreshold
  return false
}

export function registerBufferedLowEventListener (listener) {
  dataChannel.addEventListener('bufferedamountlow', listener)
}

export function unRegisterBufferedLowEventListener (listener) {
  dataChannel.removeEventListener('bufferedamountlow', listener)
}

export function getFileExplorerStructure () {
  const getFileExplorerCommand = {
    fileCommand: DATACHANNEL_FILE_EXPLORER_TOGGLE,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize: '',
    filePath: '',
    fileNames: '',
    fileData: '',
    checkSum: ''
  }
  const buffer = str2ab(JSON.stringify(getFileExplorerCommand))
  logEvent(LOG_FE_FETCH_FILEDATA_STARTED)
  sendDataChannelMessage(buffer)
}

export function sendStartDownloadCommand (fileNames, path) {
//  const fileNames = Array.isArray(selectedChildrens) ? selectedChildrens.map(file => file.name) : selectedChildrens
  const startDownloadCommand = {
    fileCommand: DATACHANNEL_FILE_DOWNLOAD_COMMAND,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize: '',
    filePath: path,
    fileNames,
    fileData: '',
    checkSum: ''
  }
  // fileNames: Array.isArray(fileNames) ? [...fileNames].toString() : fileNames,
  const buffer = Buffer.from(JSON.stringify(startDownloadCommand))
  logEvent(LOG_FE_FILEDOWNLOAD_STARTED, { fileNames, path })
  sendDataChannelMessage(buffer)
}

export function sendStartUploadCommand (path) {
  const startUploadCommand = {
    fileCommand: DATACHANNEL_FILE_UPLOAD_COMMAND,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize: '',
    filePath: path,
    fileNames: '',
    fileData: '',
    checkSum: ''
  }
  const buffer = Buffer.from(JSON.stringify(startUploadCommand))
  sendDataChannelMessage(buffer)
}

export function sendUploadFileChunk (fileSize, data) {
  const sendChunk = {
    fileCommand: DATACHANNEL_FILE_DATA_COMMAND,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize,
    filePath: '',
    fileNames: '',
    fileData: data,
    checkSum: ''
  }
  const buffer = str2ab(JSON.stringify(sendChunk))
  sendDataChannelMessage(buffer)
}

export function sendDeleteFileCommand (selectedChildrens, path) {
  const fileNames = Array.isArray(selectedChildrens) ? selectedChildrens.map(file => file.name).toString() : selectedChildrens
  const sendDeleteCommand = {
    fileCommand: DATACHANNEL_FILE_DELETE_COMMAND,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize: '',
    filePath: path,
    fileNames,
    fileData: '',
    checkSum: ''
  }
  const buffer = Buffer.from(JSON.stringify(sendDeleteCommand))
  logEvent(LOG_FE_FILEDELETE_STARTED, { fileNames, path })
  sendDataChannelMessage(buffer)
}

export function sendProvisioningCommand (toggleProvisioning) {
  const triggerProvisioningCommand = {
    fileCommand: toggleProvisioning ? DATACHANNEL_PROVISIONING_MODE_ENABLED : DATACHANNEL_PROVISIONING_MODE_DISABLED,
    fileType: DATACHANNEL_FILE_TYPE,
    fileSize: '',
    filePath: '',
    fileNames: '',
    fileData: '',
    checkSum: ''
  }
  const buffer = str2ab(JSON.stringify(triggerProvisioningCommand))
  sendDataChannelMessage(buffer)
}

export function sendDataChannelMessage (buffer) {
  if (dataChannel && dataChannel.readyState === 'open') { dataChannel.send(buffer) }
}
