import { pipe } from 'ramda'
import { connect } from 'react-redux'

import { FileExplorerActions } from '../../store'
import popup from './popup'

const mapDispatchToPrps = dispatch => ({
  startDownloading: (fileName) => dispatch(FileExplorerActions.startDownloading(fileName)),
  downloadingCompleted: () => dispatch(FileExplorerActions.downloadingCompleted()),
  downloadingFailed: () => dispatch(FileExplorerActions.downloadingFailed()),
  updateDownloadingPercentage: (downloadPercentage) => dispatch(FileExplorerActions.updateDownloadingPercentage(downloadPercentage)),
  resetCurrentDownloadingDetails: () => dispatch(FileExplorerActions.resetCurrentDownloadingDetails()),
  updateDeletingDetails: (actualPath, fileName) => dispatch(FileExplorerActions.updateDeletingDetails(actualPath, fileName))
})

export default pipe(
  connect(null, mapDispatchToPrps)
)(popup)
