import app from './app'

export default app.byteorder = (function () {
  const SIZEOF_WORD = 2
  const SIZEOF_DWORD = 4
  const NETWORK_BYTE_ORDER = true

  // public methods
  function htons (val, obj, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER
    if (networkByteOrder) {
      obj.buf[obj.index] = (0xff & (val >> 8))
      obj.buf[obj.index + 1] = (0xff & val)
    } else {
      obj.buf[obj.index + 1] = (0xff & (val >> 8))
      obj.buf[obj.index] = (0xff & val)
    }

    obj.index += SIZEOF_WORD
  }

  function ntohs (obj, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER

    let val

    if (networkByteOrder) {
      val = ((0xff & obj.buf[obj.index]) << 8) |
                  ((0xff & obj.buf[obj.index + 1]))
    } else {
      val = ((0xff & obj.buf[obj.index + 1]) << 8) |
                  ((0xff & obj.buf[obj.index]))
    }

    obj.index += SIZEOF_WORD

    return val
  }

  function htonl (val, obj, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER

    if (networkByteOrder) {
      obj.buf[obj.index] = (0xff & (val >> 24))
      obj.buf[obj.index + 1] = (0xff & (val >> 16))
      obj.buf[obj.index + 2] = (0xff & (val >> 8))
      obj.buf[obj.index + 3] = (0xff & val)
    } else {
      obj.buf[obj.index + 3] = (0xff & (val >> 24))
      obj.buf[obj.index + 2] = (0xff & (val >> 16))
      obj.buf[obj.index + 1] = (0xff & (val >> 8))
      obj.buf[obj.index] = (0xff & val)
    }

    obj.index += SIZEOF_DWORD
  }

  function ntohl (obj, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER

    let val

    if (networkByteOrder) {
      val = ((0xff & obj.buf[obj.index]) << 24) |
                  ((0xff & obj.buf[obj.index + 1]) << 16) |
                  ((0xff & obj.buf[obj.index + 2]) << 8) |
                  ((0xff & obj.buf[obj.index + 3]))
    } else {
      val = ((0xff & obj.buf[obj.index + 3]) << 24) |
                  ((0xff & obj.buf[obj.index + 2]) << 16) |
                  ((0xff & obj.buf[obj.index + 1]) << 8) |
                  ((0xff & obj.buf[obj.index]))
    }

    obj.index += SIZEOF_DWORD

    return val
  }

  function isLittleEndian () {
    if (app.nodeWebkit && app.os) {
      if (app.os.endianness() === 'LE') {
        return true // little endian
      } else {
        return true // big endian
      }
    } else {
      return ((new Uint32Array((new Uint8Array([1, 2, 3, 4])).buffer))[0] === 0x04030201)
    }
  }

  // public API
  return {
    htons,
    ntohs,
    htonl,
    ntohl,
    isLittleEndian
  }
}())
//
//
//
app.byteorder.string = (function () {
  const SIZEOF_WORD = 2
  const NETWORK_BYTE_ORDER = false

  // public methods
  function toCharCodeArray (str, length, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER

    let arr

    if (str && str.length) {
      length = length || (str.length * SIZEOF_WORD)

      arr = new Uint8Array(length) // 8-bit unsigned integer

      for (let ii = 0, index = 0; ii < length; ii++) {
        const val = str.charCodeAt(ii)

        if (networkByteOrder) {
          arr[index++] = (0xff & (val >> 8))
          arr[index++] = (0xff & val)
        } else {
          arr[index++] = (0xff & val)
          arr[index++] = (0xff & (val >> 8))
        }
      }
    }

    return arr
  }

  function fromCharCodeArray (arr, networkByteOrder) {
    networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER

    let str = ''

    if (arr && arr.length) {
      let val
      const { length } = arr

      for (let ii = 0; ii < length; ii += SIZEOF_WORD) {
        if (networkByteOrder) {
          val = ((0xff & arr[ii]) << 8) |
						  ((0xff & arr[ii + 1]))
        } else {
          val = ((0xff & arr[ii + 1]) << 8) |
						  ((0xff & arr[ii]))
        }
        str += String.fromCharCode(val)
      }
    }

    return str
  }

  // public API
  return {
    toCharCodeArray,
    fromCharCodeArray
  }
}())
