import { pipe } from 'ramda'
import { connect } from 'react-redux'

import mapStateToProps from './folder-tree.selector'
import { FileExplorerActions } from '../../store'
import FolderTree from './folder-tree'

const mapDispatchToProps = dispatch => ({
  updateSelectedFolder: (folderDetails) => dispatch(FileExplorerActions.updateSelectedFolder(folderDetails)),
  startDownloading: (fileName) => dispatch(FileExplorerActions.startDownloading(fileName))
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(FolderTree)
