import app from './app'

export default app.xml = function(){
	"use strict";
	var X2JS = {}
	function xml2json(xml) {
		var x2js = new X2JS();
		return x2js.xml_str2json(xml);
	}

	function json2xml(json) {
		var x2js = new X2JS(),
			//xml = '<?xml version="1.0" encoding="UTF-8"?>' + x2js.json2xml_str(json);
			xml = x2js.json2xml_str(json);
		return xml;
	}
	
	// public API
	return {xml2json: xml2json,
		    json2xml: json2xml};
}();
