import { createStructuredSelector } from 'reselect'

const dataChannelStatus = ({ remoteScreen }) => remoteScreen.getIn(['dataChannelStatus'], false)
const activeFolderPath = ({ fileExplorer }) => fileExplorer.getIn(['activeFolderDetails', 'path'], '')
const currentUploadingFileName = ({ fileExplorer }) => fileExplorer.getIn(['currentUploadingDetails', 'fileName'], '')

export default createStructuredSelector({
  dataChannelStatus,
  activeFolderPath,
  currentUploadingFileName
})
