export interface Typography {
  size: string;
  lineHeight: string;
}

export interface ThemeTypography {
  h1: Typography;
  h2: Typography;
  h3: Typography;
  h4: Typography;
  h5: Typography;
  subtitle: Typography;
  subtitle2: Typography;
  body: Typography;
  body2: Typography;
}

export const compact: ThemeTypography = {
  h1: {
    size: '1.625rem',
    lineHeight: '2.125rem'
  },
  h2: {
    size: '1rem',
    lineHeight: '1.5rem'
  },
  h3: {
    size: '1rem',
    lineHeight: '1.5rem'
  },
  h4: {
    size: '0.875rem',
    lineHeight: '1.375rem'
  },
  h5: {
    size: '0.75rem',
    lineHeight: '1.25rem'
  },
  subtitle: {
    size: '1rem',
    lineHeight: '1.5rem'
  },
  subtitle2: {
    size: '0.875rem',
    lineHeight: ' 1.375rem'
  },
  body: {
    size: '0.875rem',
    lineHeight: '1.375rem'
  },
  body2: {
    size: '0.75rem',
    lineHeight: '1.25rem'
  }
}
