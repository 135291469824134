import React, { useRef, useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import {
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles'

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '.875rem'
      }
    }
  }
})

const DisplayName = props => {
  const textElementRef = useRef()

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    setHover(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)

    return () => {
      window.removeEventListener('resize', compareSize)
    }
  }, [])

  const [hoverStatus, setHover] = useState(false)

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip
        title={props.name}
        disableHoverListener={!hoverStatus}
      >
        <div
          ref={textElementRef}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {props.name}
        </div>
      </Tooltip>
    </MuiThemeProvider>
  )
}

export default DisplayName
