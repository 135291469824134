import app from './app'
import config from 'Config/AppConfig'

const MOUSE_DOWN = 1
const MOUSE_MOVE = 2
const MOUSE_UP = 3

export default app.mouse = (function () {
  let moveQueue = []
  let moveID = null
  let mouseDown = false
  let mouseCoords = null
  let modelData;
  // private methods
  function getCoordinates (canvas, evt) {
    const rect = canvas.getBoundingClientRect()

    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    }
  }

  function calc (coords, offset1, offset2, offset3, virtualScreen) {
    if (virtualScreen.height > virtualScreen.width) {
      // portrait (flipped due to OS issues capturing screen)
      var val = ((virtualScreen.height + offset2) / virtualScreen.height)
      coords.y *= val

      var val = ((virtualScreen.width + offset3) / virtualScreen.width)
      coords.x *= val
    } else {
      // landscape (flipped due to OS issues capturing screen)
      var val = ((virtualScreen.width + offset1) / virtualScreen.width)
      coords.x *= val

      var val = ((virtualScreen.height + offset3) / virtualScreen.height)
      coords.y *= val
      }
  }

  function scaleCoordinates (coords) {
    const virtualScreen = app.device.getVirtualScreen()

    if (app.screenScaling) {
      coords.x /= virtualScreen.scale
      coords.y /= virtualScreen.scale
    }
    const model = app.device.getModel()
    if (modelData) {
      calc(coords, modelData.offset1, modelData.offset2, modelData.offset3, virtualScreen)
    } else {
      getDeviceModelInfo(model).then((response) => {
        if (response !== null && response.data) {
          modelData = response.data
        } else {
          modelData = {
              offset1 : 72,
              offset2 : 46,
              offset3 : 46
          }
        }
        calc(coords, modelData.offset1, modelData.offset2, modelData.offset3, virtualScreen)
      });
    }
  } 

  // public methods
  function down (event, canvas) {
    mouseDown = true
    const coords = getCoordinates(canvas, event)
    scaleCoordinates(coords)
    app.log.info('mouse down at: ' + coords.x + ', ' + coords.y)

    if (app.debug && app.adb) {
      app.adbClient.mouseEvent(coords, MOUSE_DOWN)
    } else {
      mouseCoords = { x: coords.x, y: coords.y }

      const obj = { x: coords.x, y: coords.y, state: MOUSE_DOWN }
      app.server.write(obj, (err) => {
        if (err) {
          mouseDown = false
        }
      })
    }
  }

  function up (event, canvas) {
    mouseDown = false

    const coords = getCoordinates(canvas, event)

    scaleCoordinates(coords)

    // app.log.info("mouse up at: " + coords.x + ", " + coords.y);

    if (moveID) {
      sendLastMouseMove()
    }

    if (app.debug && app.adb) {
      app.adbClient.mouseEvent(coords, MOUSE_UP)
    } else {
      const obj = { x: coords.x, y: coords.y, state: MOUSE_UP }

      app.server.write(obj, () => {})
    }
  }

  function move (event, canvas) {
    if (mouseDown) {
      const coords = getCoordinates(canvas, event)

      scaleCoordinates(coords)

      const obj = { x: coords.x, y: coords.y, state: MOUSE_MOVE }

      // app.log.info("mouse move coordinates: " + coords.x + ", " + coords.y);

      if (moveID) {
        moveQueue.push(obj)
      } else {
        // send move event
        app.server.write(obj, () => {})

        // set timeout to queue mouse move events
        moveID = setTimeout(() => {
          sendLastMouseMove()
        }, 300)
      }
    }
  }

  function sendLastMouseMove () {
    clearTimeout(moveID)
    moveID = null

    if (moveQueue.length) {
      const obj = moveQueue[moveQueue.length - 1]
      app.server.write(obj, () => {})

      moveQueue = []
    }
  }

  function wheel (event) {
    // We disabled wheel event due to its unexpected behavior.
    if (mouseCoords && false) {
      // wheel delta
      let direction
      const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)))

      // console.log("mouse wheel delta: " + delta);
      // console.log("mouse wheel delta X: " + event.deltaX);
      // console.log("mouse wheel delta Y: " + event.deltaY);
      // console.log("mouse wheel delta Z: " + event.deltaZ);

      if (delta > 0) {
        // scroll up
        direction = 'up'
      } else {
        // scroll down
        direction = 'down'
      }

      let x = Math.abs(event.deltaX)
      let y = Math.abs(event.deltaY) * 1

      if (app.debug && app.adb) {
        const duration = 100

        if (direction === 'up') {
          var obj = {
            startX: mouseCoords.x,
            startY: mouseCoords.y,
            stopX: mouseCoords.x + x,
            stopY: mouseCoords.y + y,
            duration
          }
        } else {
          var obj = {
            startX: mouseCoords.x,
            startY: mouseCoords.y,
            stopX: mouseCoords.x - x,
            stopY: mouseCoords.y - y,
            duration
          }
        }

        app.adbClient.scrollEvent(obj, (err) => {
          if (!err) {
            // console.log("scroll event sent to device");
          }
        })
      } else {
        // mouse down event
        var obj = { x: mouseCoords.x, y: mouseCoords.y, state: MOUSE_DOWN }
        app.server.write(obj, (err) => {
          if (!err) {
            // mouse move event
            x /= 4
            y /= 4

            if (direction === 'up') {
              obj = {
                x: mouseCoords.x + x,
                y: mouseCoords.y + y
              }
            } else {
              obj = {
                x: mouseCoords.x - x,
                y: mouseCoords.y - y
              }
            }

            obj.state = MOUSE_MOVE

            app.server.write(obj, (err) => {
              if (!err) {
                // mouse up event
                obj = { x: mouseCoords.x, y: mouseCoords.y, state: MOUSE_UP }

                app.server.write(obj, (err) => {
                  if (!err) {
                    // console.log("simulated mouse wheel event sent to device");
                  }
                })
              }
            })
          }
        })
      }
    }
    // return false;
  }
  
  function getDeviceModelInfo (model) {
    return fetch(`${config.signallingServer.apiURL}/getDeviceModelData?model=${model}`, {
      headers: {
        Authorization: 'Bearer ' + global.sessionStorage.getItem('accessToken'),
      }
    }).then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        return null
      }
    }).catch(() => {
      return null
    })
  }
  return {
    up,
    down,
    move,
    wheel
  }
}())
