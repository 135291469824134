import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  startSocketConnection: null,
  triggerCommand: ['deviceId', 'cmdStr'],
  updateDeviceId: ['deviceId'],
  updateDataChannelStatus: ['status'],
  reset: null
}, {
  prefix: '@REMOTESCREEN/'
})

export const RemoteScreenTypes = Types
export const RemoteScreenActions = Creators
