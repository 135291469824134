export function findNode (path, data) {
  let node
  for (let i = 0; i < data.length; i++) {
    if (path.includes(data[i].path)) {
      node = loopNodes(path, data[i])
    }
    if (node) break
  }

  return node
}

export function loopNodes (path, currentNode) {
  try {
    let i,
      currentChild,
      result

    if (path === currentNode.path) {
      return currentNode
    } else if (currentNode.children) {
      for (i = 0; i < currentNode.children.length; i += 1) {
        currentChild = currentNode.children[i]
        if (currentChild.type === 'file') {
          continue
        }
        result = loopNodes(path, currentChild)
        if (result !== false) {
          return result
        }
      }
    }
    return false
  } catch (e) {
    return false
  }
}
