const SIZEOF_BYTE = 1
const SIZEOF_WORD = 2
const SIZEOF_COMMAND_FRAME = (SIZEOF_BYTE * 2) + SIZEOF_WORD
const PROTOCOL_VERSION = 1
const COMMAND_FRAME = 4
const NETWORK_BYTE_ORDER = true

function pushByte (byte, obj) {
  if (obj && obj.buf) {
    obj.buf[obj.index++] = byte
  }
}

function pushShort (word, obj) {
  if (obj && obj.buf) {
    htons(word, obj)
  }
}

function command (number) {
  const buf = new Uint8Array(SIZEOF_COMMAND_FRAME)
  const obj = { buf, index: 0 }

  pushByte(PROTOCOL_VERSION, obj)
  pushByte(COMMAND_FRAME, obj)
  pushShort(number, obj)
  return buf
}

function htons (val, obj, networkByteOrder) {
  networkByteOrder = networkByteOrder || NETWORK_BYTE_ORDER
  if (networkByteOrder) {
    obj.buf[obj.index] = (0xff & (val >> 8))
    obj.buf[obj.index + 1] = (0xff & val)
  } else {
    obj.buf[obj.index + 1] = (0xff & (val >> 8))
    obj.buf[obj.index] = (0xff & val)
  }

  obj.index += SIZEOF_WORD
}

export function sendData (obj, dataChannel) {
  const buf = command(obj.command)
  if (dataChannel && dataChannel.readyState === 'open') { dataChannel.send(buf.toString()) }
}

export function str2ab (str) {
  const arrayBuffer = new ArrayBuffer(str.length)
  const typedBuffer = new Uint8Array(arrayBuffer)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    typedBuffer[i] = str.charCodeAt(i)
  }
  return typedBuffer
}

export function fixBinary (bin) {
  const { length } = bin
  const a = []
  for (let i = 0; i < length; i++) {
    a.push(bin.charCodeAt(i))
  }
  return a
}

export function constructJSON (command, filesize, path, fileName, data, checkSum) {
  const obj = { fileCommand: command, type: 'file', size: filesize, path, fileName, data, checkSum }
  return JSON.stringify(obj)
}
