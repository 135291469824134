import React, { useEffect, useState } from 'react'
import { getFileExplorerStructure } from 'Services/webrtc-connection'

import FolderTree from './FolderTree'
import FolderGrid from './FolderGrid'
import UserNotification from './user-notification'
import Loader from 'Components/Loader'
import config from 'Config/AppConfig'
import { logEvent, LOG_FE_FETCH_FILEDATA_FAILED } from 'Utils/log-helper'

import {
  FileExplorerContainer
} from './file-explorer.styles'

const FileExplorer = ({
  loading,
  dataChannelStatus,
  isFileExplorerAvailable,
  agentVersion,
  setFileExplorerLoading
}) => {
  const [failedToFetch, setFailedToFetch] = useState(false)
  useEffect(() => {
    setFileExplorerLoading(true)
  }, [setFileExplorerLoading])

  useEffect(() => {
    let timeOut
    if (dataChannelStatus && isFileExplorerAvailable && loading) {
      getFileExplorerStructure()
      timeOut = setTimeout(() => {
        if (loading) {
          setFileExplorerLoading(false)
          setFailedToFetch(true)
          logEvent(LOG_FE_FETCH_FILEDATA_FAILED)
        }
      }, 60000)
    }

    return () => {
      clearTimeout(timeOut)
    }
  }, [dataChannelStatus, isFileExplorerAvailable, loading, setFileExplorerLoading])

  function renderFE () {
    const SS_CLIENT_MIN_VERSION_FE = config.ssClientMinVersionFE
    if (failedToFetch) {
      return <UserNotification title='Failed to fetch file explorer data. Please try again.' />
    }

    if (dataChannelStatus && isFileExplorerAvailable) {
      return (
        <>
          <FolderTree />
          <FolderGrid />
        </>
      )
    } else if (dataChannelStatus && !isFileExplorerAvailable) {
      return (
        <UserNotification
          title='File Explorer is not available.'
          subTitle={`Agent version on your device is ${agentVersion}. Please upgrade to ${SS_CLIENT_MIN_VERSION_FE} or higher version to use this feature`}
        />
      )
    } else if (!dataChannelStatus) {
      return (
        <UserNotification title='Unable to establish connection with the device. Please try again.' />
      )
    }

    return null
  }

  return (
    <FileExplorerContainer>
      {!loading
        ? renderFE()
        : <Loader message='Please wait while we are fetching the data.' />}
    </FileExplorerContainer>
  // : <UserNotification title='Unable to establish connection with the device. Please try again.' />}
  )
}

export default React.memo(FileExplorer)
