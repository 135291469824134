import React, { useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { updateDownloadDetails } from 'Utils/file-download-helper'
import DeleteModal from '../DeleteModal'
import { downloadingInProgress } from 'Features/FileExplorer/components/FolderGrid/folder-grid.selector'
import { WarningToast } from 'Themes/ScufStyledComponents'
import {
  sendDeleteFileCommand
} from 'Services/webrtc-connection'
import {
  StyledPopup,
  StyledVerticalMenu,
  StyledIconEllipsis
} from '../FolderTree/folder-tree.styles'

const Popup = ({
  fileName,
  path,
  setDeleteModalActive,
  startDownloading,
  downloadingCompleted,
  downloadingFailed,
  updateDownloadingPercentage,
  updateDeletingDetails
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const dummyRef = useRef(null)
  const downloadingProgress = useSelector(downloadingInProgress)

  const handleDeleteModalOpenCallback = useCallback(
    () => {
      dummyRef.current.click()
      setDeleteModalActive(true)
      setIsDeleteModalOpen(true)
    }, [setDeleteModalActive]
  )

  const handleDeleteCallback = useCallback(
    () => {
      let newPath = path.split('/')
      newPath = newPath.slice(0, newPath.length - 1).join('/')
      updateDeletingDetails(path, fileName)
      sendDeleteFileCommand(fileName, newPath)
      setIsDeleteModalOpen(false)
    }, [fileName, path, updateDeletingDetails]
  )

  const handleCancelCallback = useCallback(
    () => {
      setDeleteModalActive(false)
      setIsDeleteModalOpen(false)
    }, [setDeleteModalActive]
  )

  const handleDownloadCallback = useCallback(
    () => {
      dummyRef.current.click()
      if (downloadingProgress) {
        toast(<WarningToast message='Downloading file(s) is in progress, Please wait.' />)
      } else {
        let newPath = path.split('/')
        newPath = newPath.slice(0, newPath.length - 1).join('/')

        updateDownloadDetails(fileName, newPath, { updateDownloadingPercentage, downloadingCompleted, downloadingFailed })
        startDownloading(fileName)
      }
    }, [downloadingCompleted, downloadingFailed, downloadingProgress, fileName, path, startDownloading, updateDownloadingPercentage]
  )

  return (
    <>
      <StyledPopup className='popup-theme-wrap' element={<StyledIconEllipsis root='common' name='ellipsis-vertical' size='medium' />} on='click'>
        <StyledVerticalMenu>
          <StyledVerticalMenu>
            <StyledVerticalMenu.Item onClick={handleDeleteModalOpenCallback}>Delete</StyledVerticalMenu.Item>
            <StyledVerticalMenu.Item onClick={handleDownloadCallback}>Download</StyledVerticalMenu.Item>
          </StyledVerticalMenu>
        </StyledVerticalMenu>
      </StyledPopup>

      <div ref={dummyRef} style={{ display: 'none' }} />

      <DeleteModal
        openModal={isDeleteModalOpen}
        handleDelete={handleDeleteCallback}
        handleCancel={handleCancelCallback}
      />
    </>
  )
}

export default React.memo(Popup)
