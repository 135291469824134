import React, { useEffect, useRef, useCallback } from 'react'
import { toast } from 'react-toastify'

import $ from 'jquery'
import io from 'socket.io-client'
import app from 'Legacy/UpdatedApp/app'

import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'
import { DATACHANNEL_FILE_STRUCTURE_TYPE, DATACHANNEL_FILE_TYPE, DATACHANNEL_FILE_DELETE_COMMAND, DATACHANNEL_FILE_UPLOAD_COMMAND, DATACHANNEL_FILE_DOWNLOAD_COMMAND } from 'Utils/constants'
import { getQueryParams } from 'Utils/device-helpers'
import { downloadFileSaver } from 'Utils/file-download-helper'
import { setContinueUploading } from 'Utils/upload-helper'
import config from 'Config/AppConfig'
import { createWebRTCConnection, processAnswer, processIce, sendDataChannelMessage } from 'Services/webrtc-connection'
import {
  logEvent,
  LOG_RV_INITIATING,
  LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART,
  LOG_RV_STARTED,
  LOG_RV_STOPPED,
  LOG_RV_FAILED_TO_START,
  LOG_FE_FETCH_FILEDATA_COMPLETED,
  LOG_FE_FILEDELETE_COMPLETED,
  LOG_FE_FILEDELETE_FAILED,
  LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART_SUCCESS,
  LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_FAILED,
  LOG_RV_INITIATING_SOCKET_CONNECTION,
  LOG_RV_SOCKET_CONNECTION_SUCCESS,
  LOG_RV_SESSION_EXPIRED,
  LOG_RV_UNAUTHORIZED_ACCESS,
  LOG_RV_UNABLE_TO_REACH_DEVICE,
  LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_DEVICE_OFFLINE,
  LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION,
  LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION_FAILED,
  LOG_RV_60SECONDS_TIMEOUT,
  LOG_RV_INITIATING_WEBRTC_CONNECTION,
  LOG_RV_DEVICE_USER_DECLINED,
  LOG_RV_DEVICE_AUTODISMISS_REMOTE_CONTROL_EVENT,
  LOG_RV_GET_ACCESS_TOKEN_FROM_SINAPS,
  LOG_RV_SESSION_INVALID,
  LOG_RV_PAGE_REFRESHED,
  LOG_RV_RECEIVED_ACCESS_TOKEN_FROM_SINAPS,
  LOG_RV_CHECKING_FILE_EXPLORER_AVAILABILITY,
  LOG_RV_FILE_EXPLORER_IS_AVAILABLE,
  LOG_RV_FILE_EXPLORER_IS_NOT_AVAILABLE,
  LOG_RV_WINDOW_CLOSED_OR_REFRESHED,
  LOG_RV_SS_WEBSOCKET_UNAUTHORIZED_ACCESS,
  LOG_RV_SS_ANOTHER_REMOTE_ACCESS_IS_ACTIVE
} from 'Utils/log-helper'

import {
  RemoteScreenContainer,
  StyledButtonsWrapper,
  StyledButton,
  StyledIcon,
  Shape1,
  Shape2,
  Shape3,
  Shape4
} from './remote-screen.styles'
import { findNode } from 'Utils/find-node'
import { registerKeyboardEvents } from 'Utils/keyboard-helper'

let accessToken = ''
const deviceId = getQueryParams('deviceId', window.location.href) || ''
let browserPeer = ''
let devicePeer = ''
let socketRTC = null
let waitingTimeout
let iceConfiguration = {}
let peerConnectionStarted = false
let triggerRVStop = true
let rvStarted = false

const RemoteScreen = ({
  dataChannelStatus,
  activeFolderPath,
  currentUploadingFileName,
  setFileExplorerLoading,
  updateDeviceManufacturerInfo,
  updateDataChannelStatus,
  updateFileExplorerAvailability,
  updateFileExplorerData,
  updateUploadOrDeleteResponse,
  dispatchDeleteResponse,
  uploadingCompleted,
  uploadingFailed,
  downloadingFailed
}) => {
  const pathRef = useRef(activeFolderPath)
  const currentUploadFileRef = useRef(currentUploadingFileName)
  const chunksRef = useRef({
    refreshFileData: '',
    uploadFileData: '',
    deleteFileData: '',
    downloadFileData: ''
  })

  useEffect(() => {
    pathRef.current = activeFolderPath
  }, [activeFolderPath])

  useEffect(() => {
    currentUploadFileRef.current = currentUploadingFileName
  }, [currentUploadingFileName])

  useEffect(() => {
    app.deviceId = deviceId
    app.wrtclientsendData = sendDataChannelMessage
    app.win = {
      width: 800,
      height: 800
    }
    app.device.init()
    if (config.debug) {
      startSocketRTCConnection()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', onMessageReceived, false)

    return () => window.removeEventListener('message', onMessageReceived, false)
  }, [])

  useEffect(() => {
    logEvent(LOG_RV_INITIATING)
    window.addEventListener('load', onLoad, false)

    return () => window.removeEventListener('load', onLoad, false)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload, false)

    return () => window.removeEventListener('beforeunload', beforeUnload, false)
  }, [])

  useEffect(() => {
    if (dataChannelStatus) {
      (async () => {
        logEvent(LOG_RV_STARTED)
        registerKeyboardEvents()
        const [isFileExplorerAvailable, agentVersion] = await checkFileExplorerAvailability()
        updateFileExplorerAvailability(isFileExplorerAvailable, agentVersion)
      })()
    }

    function checkFileExplorerAvailability () {
      logEvent(LOG_RV_CHECKING_FILE_EXPLORER_AVAILABILITY)
      setFileExplorerLoading(true)
      return fetch(`${config.api.connectedaidc}/devices/${deviceId}`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          apikey: config.api.apiKey
        }
      })
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            return null
          }
        }
        )
        .then(response => {
          let isFileExplorerAvailable = false
          let agentVersion = 'Not Available'
          if (response) {
            const { appVersion } = response.deviceConfiguration && response.deviceConfiguration.appsDetails[0]
            const deviceManufacturer = response.deviceIdentifier && response.deviceIdentifier.deviceManufacturer
            updateDeviceManufacturerInfo(deviceManufacturer)
            if(appVersion) {
              agentVersion = appVersion
              isFileExplorerAvailable = true
            }
            if (!isFileExplorerAvailable) {
              setFileExplorerLoading(false)
            }
          } else {
            setFileExplorerLoading(false)
          }

          if (isFileExplorerAvailable) {
            logEvent(LOG_RV_FILE_EXPLORER_IS_AVAILABLE)
          } else {
            logEvent(LOG_RV_FILE_EXPLORER_IS_NOT_AVAILABLE)
          }

          return [isFileExplorerAvailable, agentVersion]
        }).catch(e => {
          setFileExplorerLoading(false)
          return [false, 'Not Available']
        })
    }
  }, [dataChannelStatus, setFileExplorerLoading, updateFileExplorerAvailability, updateDeviceManufacturerInfo])

  function onMessageReceived (event) {
    if (event.data && event.data.accessToken && event.data.deviceId === deviceId) {
      accessToken = event.data.accessToken
      logEvent(`${LOG_RV_RECEIVED_ACCESS_TOKEN_FROM_SINAPS} : ${accessToken}`)
      if (sessionStorage) sessionStorage.setItem('accessToken', event.data.accessToken)
      fetchIceConfig()
    }
  }

  function onLoad (event) {
    if (config.debug) return
    if (sessionStorage && sessionStorage.getItem('accessToken')) {
      logEvent(LOG_RV_PAGE_REFRESHED)
      accessToken = sessionStorage.getItem('accessToken')
      fetchIceConfig()
    } else if (window.opener) {
      logEvent(LOG_RV_GET_ACCESS_TOKEN_FROM_SINAPS)
      window.opener.postMessage({ type: 'GetAccessToken', deviceId }, '*')
    } else if (!config.debug) {
      logEvent(LOG_RV_SESSION_INVALID)
      $('#user-msg').html('Unauthorized access, please login and try again.')
      $('#loading-icon').css({ display: 'none' })
      setFileExplorerLoading(false)
      triggerRVStop = false
    }
  }

  function beforeUnload () {
    logEvent(LOG_RV_WINDOW_CLOSED_OR_REFRESHED)
    window.opener && window.opener.postMessage({ type: 'ChildClosed', deviceId, triggerRVStop }, '*')
    closeRTCConnections()
  }

  function fetchIceConfig () {
    logEvent(LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART)
    triggerCommand('DEVCOMMAND-RVPSTART').then((data) => {
      if (data && data.turnConfigs && data.turnConfigs.turnUris) {
        iceConfiguration = {
          iceServers: data.turnConfigs.turnUris.map(urls => ({
            urls,
            username: data.turnConfigs.userName,
            credential: data.turnConfigs.secretKey
          }))
        }
      }
      startSocketRTCConnection()
    }).catch(error => {
      triggerRVStop = false
      handelErrorCondition()
      if (error.message === '401') {
        if (sessionStorage && sessionStorage.getItem('accessToken')) {
          logEvent(LOG_RV_SESSION_EXPIRED)
          $('#user-msg').html(`Session expired to Remote Access ${deviceId}. Please login and try again.`)
        } else {
          logEvent(LOG_RV_UNAUTHORIZED_ACCESS)
          $('#user-msg').html(`You are not authorized to Remote Access ${deviceId}`)
        }
      } else {
        logEvent(LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_DEVICE_OFFLINE)
        logEvent(LOG_RV_UNABLE_TO_REACH_DEVICE)
        $('#user-msg').html(`Unable to reach device. Please try again(${error.message}).`)
      }
    })
  }

  async function triggerCommand (cmd) {
    const triggerCommandResponse = await fetch(`${config.api.connectedaidc}/devices/${deviceId}/device-rv-commands?command=${cmd}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
        apikey: config.api.apiKey,
        'Content-Type': 'application/json'
      }
    })
    if (triggerCommandResponse.ok) {
      logEvent(LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART_SUCCESS)
      return triggerCommandResponse.json()
    }
    else if (triggerCommandResponse.status === 401) 
    {
       // if (response.status === 401) window.opener && window.opener.postMessage({ type: 'RemoteAccessDenied', deviceId }, '*')
       logEvent(LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_FAILED, { status: triggerCommandResponse.status })
      throw new Error(triggerCommandResponse.status)
    }
    else
    {
      const myJson = await triggerCommandResponse.json()
      throw new Error(myJson.statusCode)
    }
  }

  function getDeviceConnectionStatus (id) {
    logEvent(LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION)
    return fetch(`${config.signallingServer.apiURL}/getDeviceStatus?deviceId=${deviceId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(response => {
      return response.json()
    }).catch(() => {
      logEvent(LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION_FAILED)
      return null
    })
  }

  function startSocketRTCConnection () {
    logEvent(LOG_RV_INITIATING_SOCKET_CONNECTION)
    socketRTC = io(config.signallingServer.socketURL, {
      transports: ['websocket'],
      reconnection: false,
      query: {
        token: accessToken,
        deviceId,
        type: 'browser'
      }
    })
    socketRTC.on('message', socketRTCMessage)

    socketRTC.on('id', (id) => {
      logEvent(LOG_RV_SOCKET_CONNECTION_SUCCESS, { socketId: id })

      browserPeer = id
      getDeviceConnectionStatus(deviceId).then(response => {
        if (response) {
          const { isActive, clientId } = response
          if (isActive) {
            startPeerConnection({ clientId, deviceId })
            peerConnectionStarted = true
          } else {
            waitingTimeout = setTimeout(() => {
              if (!peerConnectionStarted) {
                logEvent(LOG_RV_60SECONDS_TIMEOUT)
                handelErrorCondition()
              }
            }, 60000)
          }
        } else {
          logEvent(LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION_FAILED)
          handelErrorCondition()
        }
      }).catch(() => {
        logEvent(LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION_FAILED)
      })
    })

    socketRTC.on('deviceUserDeclined', (deviceDetails) => {
      if (deviceId === deviceDetails.deviceId) {
        logEvent(LOG_RV_DEVICE_USER_DECLINED)
        clearTimeout(waitingTimeout)
        displayErrorToastMessage('User declined for Remote Control')
        handelErrorCondition()
      }
    })

    socketRTC.on('remoteControlAutoDismissed', (deviceDetails) => {
      if (deviceId === deviceDetails.deviceId) {
        logEvent(LOG_RV_DEVICE_AUTODISMISS_REMOTE_CONTROL_EVENT)
        clearTimeout(waitingTimeout)
        displayErrorToastMessage('No response from user for Remote Control')
        handelErrorCondition()
      }
    })

    socketRTC.on('deviceConnected', (deviceDetails) => {
      if (deviceId === deviceDetails.deviceId) {
        clearTimeout(waitingTimeout)
        startPeerConnection(deviceDetails)
        peerConnectionStarted = true
      }
    })

    socketRTC.on('deviceDisconnected', (deviceDetails) => {
      if (deviceId === deviceDetails.deviceId) {
        handelErrorCondition()
      }
    })
    socketRTC.on('dataChannelRestarted', (deviceDetails) => {
      if (deviceId === deviceDetails.deviceId) {
        app.tcpServer.restartImageProcessing()
      }
    })

    socketRTC.on('error', (error) => {
      if (error === '401') {
        logEvent(LOG_RV_SS_WEBSOCKET_UNAUTHORIZED_ACCESS)
        triggerRVStop = false
        $('#user-msg').html('Unauthorized access, please login and try again.')
        $('#loading-icon').css({ display: 'none' })
      } else if (error === '101') {
        logEvent(LOG_RV_SS_ANOTHER_REMOTE_ACCESS_IS_ACTIVE)
        triggerRVStop = false
        $('#user-msg').html('Another remote access is currently active on this device. Please try later.')
        $('#loading-icon').css({ display: 'none' })
      }
    })

    socketRTC.on('deviceError', (errorDetails) => {
      if (deviceId === errorDetails.deviceId) {
        handelErrorCondition()
      }
    })

    socketRTC.on('connect_error', () => {
      handelErrorCondition()
    })
  }

  function socketRTCMessage (json) {
    if (json.type === 'candidate') {
      if (json.to === browserPeer) {
        processIce(json.payload)
      }
    } else if (json.type === 'offer') {
      // incoming offer
      if (json.to === browserPeer) {
        devicePeer = json.from
        // processOffer(json.payload)
      }
    } else if (json.type === 'answer') {
      // incoming answer
      if (json.to === browserPeer) {
        processAnswer(json.payload)
      }
    }
  }

  function sendNegotiation (type, sdp) {
    socketRTC && socketRTC.emit('message', {
      to: devicePeer,
      from: browserPeer,
      type,
      payload: sdp
    })
  }

  function startPeerConnection (deviceDetails) {
    if (peerConnectionStarted) return

    logEvent(LOG_RV_INITIATING_WEBRTC_CONNECTION)
    devicePeer = deviceDetails.clientId

    const successErrorcallbacks = {
      succesDataChannelOpenCallback,
      successDataChannelCallback,
      handelErrorCondition
    }
    createWebRTCConnection(iceConfiguration, sendNegotiation, successErrorcallbacks)
  }

  function setImage (obj) {
    app.tcpServer.webRTCmessage(obj)
  }

  function handelErrorCondition () {
    if (rvStarted) logEvent(LOG_RV_STOPPED)
    else logEvent(LOG_RV_FAILED_TO_START)
    $('#user-msg').css({ display: 'block' })
    $('#user-msg').html('Unable to establish connection with the device. Please try again.')
    $('#canvas-box').css({ display: 'none' })
    $('#loading-icon').css({ display: 'none' })
    $('#canvas').css({ display: 'none' })
    closeRTCConnections()
    setFileExplorerLoading(false)
  }

  function closeRTCConnections () {
    socketRTC && socketRTC.close()
    socketRTC = null
    updateDataChannelStatus(false)
  }

  function succesDataChannelOpenCallback () {
    rvStarted = true
    updateDataChannelStatus(true)
  }

  function successDataChannelCallback (data) {
    if (data.type === DATACHANNEL_FILE_STRUCTURE_TYPE) {
      if (data.action) {
        findActionType(data)
      } else {
        handleFileExplorerData(data)
      }
    } else if (data.type === DATACHANNEL_FILE_TYPE) {
      downloadFileSaver(data)
    } else {
      setImage(data)
    }
  }

  function handleFileExplorerData (data) {
    if (data.dataType === 'true') {
      appendFileData(data, 'refresh')
    } else {
      logEvent(LOG_FE_FETCH_FILEDATA_COMPLETED)
      const provisioingMode = (data.provisionStatus === 'true')
      appendFileData(data, 'refresh')
      const decodedData = Buffer.from(chunksRef.current.refreshFileData, "base64").toString()
      updateFileExplorerData(JSON.parse(decodedData), provisioingMode)
      chunksRef.current.refreshFileData = ''
    }
  }

  function findActionType (data) {
    if (data.action === DATACHANNEL_FILE_DELETE_COMMAND) {
      updateDeleteResponse(data)
    } else if (data.action === DATACHANNEL_FILE_UPLOAD_COMMAND) {
      updateUploadResponse(data)
    } else if (data.action === DATACHANNEL_FILE_DOWNLOAD_COMMAND) {
      updateDownloadResponse(data)
    }
  }

  function appendFileData (data, actionType) {
    const { fileData } = data
    switch (actionType) {
      case 'refresh':
        chunksRef.current.refreshFileData = chunksRef.current.refreshFileData.concat(fileData)
        break
      case DATACHANNEL_FILE_UPLOAD_COMMAND:
        chunksRef.current.uploadFileData = chunksRef.current.uploadFileData.concat(fileData)
        break
      case DATACHANNEL_FILE_DELETE_COMMAND:
        chunksRef.current.deleteFileData = chunksRef.current.deleteFileData.concat(fileData)
        break
      case DATACHANNEL_FILE_DOWNLOAD_COMMAND:
        chunksRef.current.downloadFileData = chunksRef.current.deleteFileData.concat(fileData)
        break
      default:
        chunksRef.current = {
          refreshFileData: '',
          uploadFileData: '',
          deleteFileData: '',
          downloadFileData: ''
        }
    }
  }

  function updateDeleteResponse (data) {
    const { statusCode, dataType } = data
    if (dataType === 'true') {
      appendFileData(data, DATACHANNEL_FILE_DELETE_COMMAND)
    } else {
      appendFileData(data, DATACHANNEL_FILE_DELETE_COMMAND)
      const deleteData = {
        fileData: chunksRef.current.deleteFileData,
        path: data.path
      }
      if (statusCode === '200') {
        displaySuccessToastMessage('File(s) are deleted successfully.')
        logEvent(LOG_FE_FILEDELETE_COMPLETED)
      } else {
        displayErrorToastMessage('Failed to delete file(s).')
        logEvent(LOG_FE_FILEDELETE_FAILED)
      }
      handleDeleteResponse(deleteData)
      chunksRef.current.deleteFileData = ''
    }
  }

  function updateUploadResponse (data) {
    const { statusCode, dataType } = data
    if (dataType === 'true') {
      appendFileData(data, DATACHANNEL_FILE_UPLOAD_COMMAND)
    } else {
      appendFileData(data, DATACHANNEL_FILE_UPLOAD_COMMAND)
      const uploadData = {
        fileData: chunksRef.current.uploadFileData,
        path: data.path
      }
      if (statusCode === '200') {
        displaySuccessToastMessage(`${currentUploadFileRef.current} file is uploaded successfully.`)
        uploadingCompleted(currentUploadFileRef.current)
      } else {
        displayErrorToastMessage(`Failed to upload ${currentUploadFileRef.current} file .`)
        uploadingFailed(currentUploadFileRef.current)
        setContinueUploading(false)
      }
      updateResponse(uploadData)
      chunksRef.current.uploadFileData = ''
    }
  }

  function updateDownloadResponse (data) {
    const { dataType } = data
    if (dataType === 'true') {
      appendFileData(data, DATACHANNEL_FILE_DOWNLOAD_COMMAND)
    } else {
      appendFileData(data, DATACHANNEL_FILE_DOWNLOAD_COMMAND)
      const downloadData = {
        fileData: chunksRef.current.downloadFileData,
        path: data.path
      }
      displayErrorToastMessage('Failed to download file .')
      downloadingFailed()
      updateResponse(downloadData)
      chunksRef.current.downloadFileData = ''
    }
  }

  function updateResponse (data) {
    const parsedData = parseData(data)
    if (parsedData) {
      updateUploadOrDeleteResponse(...parsedData)
    }
  }

  function handleDeleteResponse (data) {
    const parsedData = parseData(data)
    if (parsedData) {
      dispatchDeleteResponse(...parsedData)
    }
  }

  function parseData (data) {
    const { fileData, path } = data
    if (fileData) {
      const buf = Buffer.from(fileData.toString(), 'base64').toString()
      const decodedData = JSON.parse(buf)
      const node = findNode(path, decodedData)
      let nodeChildren = null
      if (node && node.children) nodeChildren = node.children
      return [decodedData, nodeChildren, path]
    }
    return null
  }

  function displaySuccessToastMessage (message) {
    toast(<SuccessToast message={message} />)
  }

  function displayErrorToastMessage (message) {
    toast(<ErrorToast message={message} />)
  }

  const handleButtonClick = useCallback(
    (event) => {
      const cmdOption = event.currentTarget.dataset.command
      if (cmdOption === undefined || cmdOption === null) return
      app.server.write({ command: parseInt(cmdOption) }, () => { })
    }, []
  )

  return (
    <RemoteScreenContainer id='rv-area'>
      <div className='left-box'>
        <Shape1 />
        <div className='bar1'>
          <StyledButtonsWrapper className='ui vertical vertical-menu shadow menu'>
            <StyledButton className='item' data-command='1' onClick={handleButtonClick}>
              <StyledIcon root='common' name='app-launcher' size='small' />
              <span className='content-wrap'>App Switch</span>
            </StyledButton>
            <StyledButton className='item' data-command='3' onClick={handleButtonClick}>
              <StyledIcon root='common' name='arrow-left' size='small' />
              <span className='content-wrap'>Back</span>
            </StyledButton>
            <StyledButton className='item' data-command='8' onClick={handleButtonClick}>
              <StyledIcon root='common' name='home1' size='small' />
              <span className='content-wrap'>Home</span>
            </StyledButton>
            <StyledButton className='item' data-command='9' onClick={handleButtonClick}>
              <StyledIcon root='common' name='menu' size='small' />
              <span className='content-wrap'>Menu</span>
            </StyledButton>
            <StyledButton className='item' data-command='13' onClick={handleButtonClick}>
              <StyledIcon root='common' name='search' size='small' />
              <span className='content-wrap'>Search</span>
            </StyledButton>
            <StyledButton className='item' data-command='5' onClick={handleButtonClick}>
              <StyledIcon root='common' name='camera' size='small' />
              <span className='content-wrap'>Camera</span>
            </StyledButton>
            <StyledButton className='item' data-command='6' onClick={handleButtonClick}>
              <StyledIcon root='common' name='user' size='small' />
              <span className='content-wrap'>Contacts</span>
            </StyledButton>
          </StyledButtonsWrapper>
        </div>
        <Shape2 />
      </div>
      <div className='center-box'>
        <div id='loading-icon' className='loader'>Loading...</div>

        <div id='user-msg' className='errorMessage'>Please wait while the device is accessed.</div>

        <div id='canvas-box' className='device' style={{ display: 'none' }} tabIndex='0'>
          <canvas id='canvas' width='360' height='600' />
          <canvas id='canvas-full' style={{ display: 'none' }} />
          <canvas id='canvas-xor' style={{ display: 'none' }} />
        </div>
      </div>
      <div className='right-box'>
        <Shape3 />
        <div className='bar2'>
          <StyledButtonsWrapper className='ui vertical vertical-menu shadow menu'>
            <StyledButton className='item' data-command='10' onClick={handleButtonClick}>
              <StyledIcon root='common' name='caret-up' size='small' />
              <span className='content-wrap'>Page Up</span>
            </StyledButton>
            <StyledButton className='item' data-command='11' onClick={handleButtonClick}>
              <StyledIcon root='common' name='caret-down' size='small' />
              <span className='content-wrap'>Page Down</span>
            </StyledButton>
            <StyledButton className='item' data-command='4' onClick={handleButtonClick}>
              <StyledIcon root='common' name='call-contacts' size='small' />
              <span className='content-wrap'>Call</span>
            </StyledButton>
            <StyledButton className='item' data-command='7' onClick={handleButtonClick}>
              <StyledIcon root='common' name='email' size='small' />
              <span className='content-wrap'>Email</span>
            </StyledButton>
            <StyledButton className='item' data-command='19' onClick={handleButtonClick}>
              <StyledIcon root='common' name='unlock' size='small' />
              <span className='content-wrap'>Unlock</span>
            </StyledButton>
            <StyledButton className='item' data-command='12' onClick={handleButtonClick}>
              <StyledIcon root='common' name='power' size='small' />
              <span className='content-wrap'>Screen On/Off</span>
            </StyledButton>
            <StyledButton className='item' data-command='20' onClick={handleButtonClick}>
              <StyledIcon root='common' name='refresh' size='small' />
              <span className='content-wrap'>Reboot</span>
            </StyledButton>
          </StyledButtonsWrapper>
        </div>
        <Shape4 />
      </div>
    </RemoteScreenContainer>
  )
}

export default React.memo(RemoteScreen)
