import { createStructuredSelector, createSelector } from 'reselect'

const activeFolderDetails = ({ fileExplorer }) => fileExplorer.getIn(['activeFolderDetails'], {})
const fileStructureData = ({ fileExplorer }) => fileExplorer.getIn(['data'], [])
const provisioningMode = ({ fileExplorer }) => fileExplorer.getIn(['provisioningMode'], false)
const uploadingInProgress = ({ fileExplorer }) => fileExplorer.getIn(['currentUploadingDetails', 'inProgress'], false)
const uploadingFailedStatus = ({ fileExplorer }) => fileExplorer.getIn(['currentUploadingDetails', 'failedStatus'], false)
const uploadWaitingResponseDetails = ({ fileExplorer }) => fileExplorer.getIn(['uploadWaitingResponseDetails'], [])
const deviceManufacturerInfo = ({ fileExplorer }) => fileExplorer.getIn(['deviceManufacturerInfo'], 'null')
export const downloadingInProgress = ({ fileExplorer }) => fileExplorer.getIn(['currentDownloadingDetails', 'inProgress'], false)
const downloadingFailedStatus = ({ fileExplorer }) => fileExplorer.getIn(['currentDownloadingDetails', 'failedStatus'], false)
const currentDownloadingDetails = ({ fileExplorer }) => fileExplorer.getIn(['currentDownloadingDetails'], {})
const downloadingDetails = createSelector(
  [currentDownloadingDetails],
  (details) => {
    const { fileName, inProgress, failedStatus, downloadPercentage } = details
    return {
      downloadFileName: fileName,
      downloadInProgress: inProgress,
      downloadFailedStatus: failedStatus,
      downloadPercentage
    }
  }
)

export default createStructuredSelector({
  fileStructureData,
  activeFolderDetails,
  provisioningMode,
  uploadingInProgress,
  uploadingFailedStatus,
  uploadWaitingResponseDetails,
  deviceManufacturerInfo,
  downloadingInProgress,
  downloadingFailedStatus,
  downloadingDetails
})
