import styled from 'styled-components/macro'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%
`

export const LoaderIcon = styled.div`
    color: #1792e5;
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
`

export const Message = styled.div`
    font-size: 1.5em;
    font-weight: 500;
    color: #404040;
    margin: 1rem;
    text-align: center;
`
