import $ from 'jquery'

var app = {
  deviceId: null,
  wrtclientsendData: null,
  adb: false,
  debug: true,
  verbose: false,
  browser: false,
  phonegap: false,
  nodeWebkit: false,
  displayKeypad: false,
  screenScaling: false,
  saveWindowPosition: false,
  imageQueue: [],
  port: 80,
  percentOfClientArea: 0.99,
  // percentOfClientArea: 1,
  menubar: {
    keypad: [],
    preferences: []
  },
  // constructor
  initialize () {
    $(document).ready(function () {
      if (app.isMobile()) {
        

        // wait for cordova to load
        $(document).on('deviceready', function () {
          // cordova loaded
          app.phonegap = true
          

          function success (filesystem) {
            app.fs = filesystem
            

            app.startClient()
          };

          function fail (error) {
           
          }

          window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, success, fail)
          // window.requestFileSystem(window.LocalFileSystem.TEMPORARY, 0, success, fail);
        })
      } else if ((typeof process !== 'undefined' && process.versions['node-webkit'])) {
        // NW.js
        app.initNWJS()

        // app.storage.removeItem(); // debug only!!!

        app.windowState.init()
        app.native.menuBar()
        app.device.init()

        app.win.on('close', function () {
          app.device.appShutdown()
        })

        if (app.debug && app.adb) {
          app.adbClient.start()
        } else {
          app.server.start(app.port)
        }
      } else {
        // browser
        app.browser = true
        
        app.win = { width: 800, height: 800 }
        app.device.init()
      }
    })
  },
  startCloudApp () {

    // app.server.start("4005");
  },
  isMobile () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      return true
    }
    return false
  },

  initNWJS () {
    app.nodeWebkit = true

    /* app.os = require("os");
		app.fs = require("fs");
		app.gui = require("nw.gui");
		app.win = app.gui.Window.get(); */
    app.os = null
    app.fs = null
    app.gui = null
    app.win = null

    app.log.info('Running under NW.js (node-webkit)')
    app.log.info('cwd: ' + process.cwd().replace(/\\/g, '/'))

    app.processCmdLine()
  },

  processCmdLine () {
    if (app.nodeWebkit) {
      var path
      var commands = app.gui.App.argv

      if (commands && commands.length) {
        // app.log.info("Command line args: " + commands);

        for (var ii = 0; ii < commands.length; ii++) {
          var arg = commands[ii].split('=')

          if (arg[0].toLowerCase() === '--port') {
            if (arg.length > 1) {
              var port = parseInt(arg[1])

              if (!isNaN(port)) {
                app.port = port
                app.log.info('Command line arg: --port=' + port)
              }
            }
          } else if (arg[0].toLowerCase() === '--pfx') {
            if (app.debug) {
              var file = 'certs/ClientCert.pfx'
              var data = app.fs.readFileSync(file)
              var b64 = new Buffer(data).toString('base64')

              app.fs.writeFileSync('c:/honeywell/base64.txt', b64)
            } else {
              var b64 = arg[1]
            }

            var pfx = new Buffer(b64, 'base64')
            app.tcpServer.pfxFile(pfx)
          } else if (arg[0].toLowerCase() === '--passphrase') {
            app.tcpServer.pfxPassphrase(arg[1])
          } else if (arg[0].toLowerCase() === '--adb') {
            if (app.debug) {
              app.adb = true
              app.log.info('Command line arg: --adp')
              app.log.info('ADB client mode enabled')
            }
          } else if (arg[0].toLowerCase() === '--verbose') {
            app.verbose = true
          }
        }
      } else {
        app.log.info('No command line arguments')
      }
    }
  },

  startClient () {
    app.client.start()
  },

  // determine if running under NW.js, node.js, or browser
  detectEnvironment () {
    if ((typeof process !== 'undefined' && process.versions['node-webkit'])) {
      // NW.js
      app.nodeWebkit = true

      // app.fs = require("fs");
      // app.gui = require("nw.gui");

      app.fs = null
      app.gui = null

      app.win = app.gui.Window.get()

     
    } else {
      // node.js
      app.nodeWebkit = false

      // Establish the root object, `window` in the browser, or `global` on the server.
      var root = this

      // Create a reference to this
      var _ = new Object()

      app.node = false

      // Export the Underscore object for **CommonJS**, with backwards-compatibility
      // for the old `require()` API. If we're not in CommonJS, add `_` to the
      // global object.
      if (typeof module !== 'undefined' && module.exports) {
        module.exports = _
        root._ = _
        app.node = true
      } else {
        root._ = _
        app.browser = true
      }
    }
  }
}

// app entry point
app.startCloudApp()

export default app
