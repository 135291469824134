import styled, { keyframes } from 'styled-components/macro'
import whiteLogo from '../../Assets/honeywell-logo-white.svg'
import { isMobile } from '../../Utils/constants'
import { Footer, Header, Icon } from '@scuf/common'

const AppFrameContainer = styled.section`
  height: 100vh;
  overflow: hidden;
`

export const ActionBarContainer = styled.div`
  display:flex;
  background: transparent;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
`
export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'} ;
`
export const ActionBarOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  & * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#1B1D1F'};
  }
  &:hover * {
    color: ${({ disabled }) => disabled ? '#A0A0A0' : '#606060'};
  }
`
export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1.2rem !important;
  margin-top: 0.15rem;
  padding-left: 1.5rem;
`
export const ActionText = styled.div`
  font-size: 14px;
  color: #303030;
  padding-left: 0.25rem;
`

const collapse = keyframes`
  from {
    width: 'calc(100vw - 3.5rem)'
  }
  to {
    width: 'calc(100vw - 14.25rem)'
  }
`

const noCollapse = keyframes`
  from {
    width: 'calc(100vw - 14.25rem)'
  }
  to {
    width: 'calc(100vw - 3.5rem)'
  }
`
const ContentWrapper = styled.div`
  height: ${({ noHeader }) => noHeader
    ? '100vh'
    : isMobile()
      ? '90vh'
      : '90vh'};
  animation: ${({ collapsed, noHeader }) => collapsed && !noHeader ? collapse : noCollapse} 1s linear infinite;
  height: ${({ noHeader }) => noHeader ? '95vh' : '90vh'};

  height: ${({ noHeader }) => noHeader
    ? '100vh'
    : isMobile()
      ? '81vh'
      : '90vh'};
   
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  
  @media (max-width: 768px) {
    width: 100vw !important;
    height: 84vh !important;
  }
`

const StyledHeader = styled(Header)`
  & .profile-icon {
    border: solid 1px #f0f0f0;
    text-align: center;

  }
  & .page-header-menu.ui.menu .menu-btn-item {
    padding: 0.75rem 1.35rem !important;
  }
  & .honeywell-logo {
    margin-left: .8rem;
  }
  & .menu .transition .right .visible {
    position: absolute;
    z-index: 10;
  }
  & .page-header-menu.ui.menu .ui.dropdown.icon-menu{
    @media (max-width: 768px) {
      z-index: 10;
      position: absolute;
      transform: translateX(-40px);
    }
  }
`

const TitleStyled = styled.div`
font-size:.75rem;
margin-right:.5rem;
font-weight:800;
color:#404040;
line-height:49px;
font-family:Honeywell Sans Web,Arial,Helvetica,sans-serif;
`

const StyledFooter = styled(Footer)`
  display:none !important;
  position: absolute;
  bottom: 0;
  font-size: 16px;
  z-index: 2;
  width: 100%;
  min-height: 2.5rem;
  justify-content:center !important;
  padding: 0rem !important;
  background-color: ${props => props.theme.black};
  & .footer-menu a {
      color: ${props => props.theme.h1color}
  }
  & .page-footer.ui.menu .link:hover {
    background: ${props => props.theme.inputback}
  }
  @media (max-width: 768px) {
    position: fixed;
    border-top: 1px solid #d0d0d0 !important;
  }
`

// DARK THEME SECTION

const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background:${props => props.theme.background};
  color:'${props => props.theme.sidebarcolor}';
  & div {color:${props => props.theme.sidebarcolor};}
  & h1 {color:${props => props.theme.h1color}}
  & h2 {color:${props => props.theme.h2color}}
  & h4 {color:${props => props.theme.h4color}}
  & h3 {color:${props => props.theme.h1color}}
  & p {color:${props => props.theme.h1color}}

  & .copyright {color:${props => props.theme.h1color}}

  & .iMoQSY {color:${props => props.theme.h1color}}

  & .highcharts-tooltip-label {color:${props => props.theme.grayback}}

  & .highcharts-tooltip-value {color:${props => props.theme.grayback}}

  & .profile-icon {color:${props => props.theme.h1color}}

  & .profile-icon {background:${props => props.theme.background}}

  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown, .ui.scuf-dropdown-wrapper > .ui.multiple.selection.dropdown, .ui.scuf-dropdown-wrapper > .ui.dropdown.disabled
   {
       color:${props => props.theme.inputcolor};
       background-color:${props => props.theme.inputback};
       border:${props => props.theme.inputborder} }
  & .ui.dropdown > .text {
    color:${props => props.theme.inputcoloractive}
  }

  & .ui.active.visible.selection.dropdown .visible.menu.transition {
    background-color:${props => props.theme.inputback};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item {
        color:${props => props.theme.inputcoloractive};
  }
  & .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item:hover  {
        background-color:${props => props.theme.sideactivehover};
}
& .ui.active.visible.selection.dropdown .selected.item {
    background-color:${props => props.theme.sideactivehover};
}

 & .page-footer.ui.menu {
  background-color: ${props => props.theme.black};
  color:${props => props.theme.headercolor};
  border-top: ${props => props.theme.inputborder};
 }

  & .footer-menu a {
    color: ${props => props.theme.h1color}
  }

  & .ui.page-header .page-header-menu.ui.menu .main-logo {
    background: ${props => props.theme.logomain};
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-origin: content-box !important;
    background-position: 0 4px !important;
  }
  & .ui.page-header {
      background-color:${props => props.theme.backgroundheader};
      border-bottom: ${props => props.theme.headerborder}
    }
  & .ui.page-header .page-header-menu.ui.menu .item {
      color:${props => props.theme.headercolor}
  }
  & .ui.page-header .page-header-menu.ui.menu .item:hover {
    background-color:${props => props.theme.headerhover}
}
  & .ui.page-header .page-header-menu.ui.menu .logo-text {color: ${props => props.theme.logotextcolor}}
  & .ui.page-header .page-header-menu.ui.menu .user-profile-menu .menu > .item  {
    background: ${props => props.theme.inputback};
    color:${props => props.theme.h1color};
    border-bottom: ${props => props.theme.inputborder};
  }
  & .ui.page-header .page-header-menu.ui.menu .user-profile-menu .menu > .item:hover  {
    background: ${props => props.theme.headerhover}
  }
  & .ui.dropdown .menu {
      border: ${props => props.theme.inputborder}
  }
  & .ui.page-header .page-header-menu.ui.menu .item.active {
    background: ${props => props.theme.inputback};
  }
  & .sidebar-layout > .sidebar {
    background: ${props => props.theme.sidebarback};
    border-right: ${props => props.theme.sidebarborder};
    width: ${() => isMobile() ? '100vw' : '14.25rem'};
  }

  & .sidebar-layout.collapse > .sidebar {
    width: ${() => isMobile() ? '0vw' : '3.5rem'};
  }

  & .sidebar-layout > .sidebar .ui.vertical.menu {
    width: ${() => isMobile() ? '100vw' : '14.25rem'};
  }

  & .sidebar-layout > .sidebar .ui.menu .item > .sidebar-label {color: ${props => props.theme.sidebarcolor}}
  & .sidebar-layout > .sidebar .ui.menu .item.active {
      background: ${props => props.theme.sidebaractive};
      color:${props => props.theme.sidebarcoloractive}}
  & .sidebar-layout > .sidebar .submenu > .ui.menu .item.active {background:${props => props.theme.sidebaractive}}
  & .sidebar-layout > .sidebar .submenu.open {background: ${props => props.theme.sidebaractive}}
  & .sidebar-layout > .sidebar .ui.menu .item {color: ${props => props.theme.sidebaricons}}
  & .sidebar-layout > .sidebar .ui.menu .item:hover {color: ${props => props.theme.sidebaricons}}
  & .sidebar-layout > .sidebar .ui.menu .item:hover  {background:${props => props.theme.sideactivehover}}
  & .ui.vertical.menu .menu .item {background:${props => props.theme.sideactiveitem}}
  & .ui.vertical.menu .menu .item:hover {background:${props => props.theme.sideactivehover}}


  & i {color:${props => props.theme.iconscolor}}

  & div.ui.input-label .input-label-message {color:${props => props.theme.h4color}}
  & .ui.single-input .input-box {
    background: ${props => props.theme.inputback};
    color:${props => props.theme.h1color};
    border-color: ${props => props.theme.inputbordercolor}
  }


  & .dashboard-card {background-color: ${props => props.theme.dashboardcard} }

  & .ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody > tr:hover {
    background-color: ${props => props.theme.inputbordercolor} !important;
  }
  & .ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody {
    border-color: ${props => props.theme.inputbordercolor} !important;
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead th {
    background: ${props => props.theme.background};
    border-top:none !important;
    border-color: ${props => props.theme.inputbordercolor};
  }
  & .datatable > div {
    background:${props => props.theme.background};
    border-color: ${props => props.theme.inputbordercolor}
  }
  & .hKMNCo {
    background:${props => props.theme.background};
  }
  & .datatable > div > div > div {
    border-color: ${props => props.theme.inputbordercolor};
    color: ${props => props.theme.bodyfontcolor};
  }
  & .datatable > div > div > div > p{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .ui.radio.checkbox > label{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .ui.checkbox > label{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .datatable > div > div > div > div{
    color: ${props => props.theme.bodyfontcolor}
  }
  & .kvmCUn:nth-child(odd){
    background: ${props => props.theme.black}
  }
  & .kvmCUn:nth-child(even){
    background: ${props => props.theme.background}
  }
  & .kvmCUn:nth-child(odd):hover{
    background: ${props => props.theme.sidebarborder} !important;
  }
  & .kvmCUn:nth-child(even):hover{
    background: ${props => props.theme.sidebarborder} !important;
  }
  & .user-table {
    background: ${props => props.theme.black}
  }
  & .Action-Bar > div > div > div > div {
    color: ${props => props.theme.bodyfontcolor}
  }
  & .Action-Bar > div > div > div  {
    background: ${props => props.theme.background}
  }

  & .debug-table .kvmCUn:nth-child(odd) {
    background:${props => props.theme.grayback};
  }
  & .debug-table .kvmCUn:nth-child(even) {background:${props => props.theme.black}}

  & .tools-item > div {
  background:transparent !important;
  }

  & .ui.checkbox label:before {
    background:${props => props.theme.background};
    border: ${props => props.theme.inputborder}
  }

  & .ui.search-wrap .ui.search.ui-search .prompt {
      background:${props => props.theme.inputback};
      color: ${props => props.theme.h1color};
      border: ${props => props.theme.inputborder}
  }
  & .ui.search-wrap .search-icon {
    background: ${props => props.theme.intercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item {
    color: ${props => props.theme.h1color};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item{
    color: ${props => props.theme.inactivecolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item {
    color: ${props => props.theme.tabcolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .active.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .disabled.item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & div.ui.pointing.secondary.ui.scuf-tab.menu .item:hover {
    color: ${props => props.theme.hovercolor};
  }
  & .update-text > div > div {
      color:${props => props.theme.h1color};
  }

  & .ui.card {
    background-color:${props => props.theme.dashboardcard};
  }
  & .ui.card .header {
    color:${props => props.theme.h1color};
  }

  & .meta-content { color:${props => props.theme.secondarycolor}}

  & .ui.tree {
    background:${props => props.theme.sidebarback}
  }
  & .tree-content .tree-item .content.active {
    background:${props => props.theme.black}
  }

  & .tree-content .tree-item .content:hover {
    background:${props => props.theme.black}
  }
  & .child-open .tree-item has-children:hover {
    background:${props => props.theme.black}
  }
  & .site-form form > div {
    background-color:${props => props.theme.dashboardcard};
    border-right:${props => props.theme.inputborder}}
  & .user-permissions > div > div > button{
        background-color:${props => props.theme.tagbackground}}
  & .tree-container > div {
    background:${props => props.theme.dashboardcard}}
  & .tree-container {
      border:${props => props.theme.inputborder}}
  & .site-loader div {
    background-color:${props => props.theme.background};
    color:${props => props.theme.h1color}
  }
  & .device-card {
    background: ${props => props.theme.inputback}
  }
  & .device-info-card {
    background: ${props => props.theme.inputback}
  }
  & .hierarchy-container {
    background: ${props => props.theme.inputback}
  }
  & .ui.notification {
    background:${props => props.theme.background}}
  & .pane-container {
    background:${props => props.theme.background}
  }
  & .notification-table {
    background-color:${props => props.theme.inputback}
  }
  & .alerts-wrapper {
    background-color:${props => props.theme.inputback}
  }
  & .alerts-filter-container > div {
    background: ${props => props.theme.background}
  }

  & .badge-warning {
    color: #be271c !important;
  }

  & .badge-info {
    color: #1792e5 !important;
  }

  & .badge-alert {
    color: #ffc627 !important;
  }

  & .alerts-container .badge-warning {
    color: #be271c !important;
  }

  & .alerts-container .badge-info {
    color: #1792e5 !important;
  }

  & .alerts-container .badge-alert {
    color: #ffc627 !important;
  }

  & .notifications-wrapper .badge-warning {
    color: #be271c !important;
  }
  & .notifications-wrapper .badge-info {
    color: #1792e5 !important;
  }

  & .notifications-wrapper .badge-alert {
    color: #ffc627 !important;
  }

  & .dashboard-notification .badge-warning {
    color: #be271c !important;
  }
  & .dashboard-notification .badge-info {
    color: #1792e5 !important;
  }

  & .dashboard-notification .badge-alert {
    color: #ffc627 !important;
  }

  & .page-footer.ui.menu .link:hover {
    background: ${props => props.theme.inputback}
}

  & .highcharts-series-1  .highcharts-graph {
  stroke: ${props => props.theme.inputcolor};
}
  & .highcharts-series-2  .highcharts-graph {
  stroke: ${props => props.theme.inputcolor};
}


& .ui.forge-datatable-wrap > div > div {
  background: ${props => props.theme.inputback};
  border: ${props => props.theme.inputborder};
}
& .data-tablestyles__FilterContainer-sc-1mus3iu-8 {
  background: ${props => props.theme.background};
}

& .p-datatable .p-datatable-thead > tr {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th.p-sortable-column:hover {
  background: ${props => props.theme.black};
  border-color: ${props => props.theme.inputbordercolor};
}

& .p-datatable .p-datatable-thead > tr > th:hover {
  background: ${props => props.theme.background};
}
& .p-datatable .p-datatable-thead > tr > th > span {
  color: ${props => props.theme.h1color};
}
& .p-datatable tbody > tr.p-datatable-row:nth-child(odd) {
  background-color: ${props => props.theme.black};
}

& .p-datatable tbody > tr.p-datatable-row:nth-child(even) {
  background-color: ${props => props.theme.background};
}

& .p-datatable .p-checkbox-box {
  background: ${props => props.theme.background};
  border: ${props => props.theme.inputborder};
}
& .ui.single-input .input-box {
  background: ${props => props.theme.inputback};
  color: ${props => props.theme.h1color};
  border-color: ${props => props.theme.inputbordercolor};
}
& .ui.single-input .search-icon {
  background: ${props => props.theme.intercolor};
}
& .p-datatable .p-datatable-tbody > tr {
  background: ${props => props.theme.background};
  color: ${props => props.theme.h1color};
}

& .p-datatable .p-datatable-header .total-count {
  color: ${props => props.theme.h1color};
}

& .data-tablestyles__HeaderRow-sc-1mus3iu-0 {
  background: ${props => props.theme.background};
}
& .ui.scuf-dropdown-wrapper > div > input {
  color: ${props => props.theme.bodyfontcolor};
}
background: linear-gradient(180deg, #FFFFFF   0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(234,236,237,0.99) 0%, #9499A2 100%);
`
const themeDark = {
  black: '#000 !important',
  background: '#202020 !important',
  headerborder: '#606060 0.0625rem solid !important',
  backgroundheader: '#000',
  bodyfontcolor: '#fff !important',
  h1color: '#fff !important',
  h2color: '#f7f7f7 !important',
  h4color: '#c0c0c0',
  headercolor: '#fff',
  headerhover: '#202020 !important',
  logotextcolor: '#fff',
  sidebarback: '#000',
  sidebarcolor: '#fff',
  sidebarborder: '#404040',
  sidebaractive: '#303030',
  iconscolor: '#fff !important',
  sidebaricons: '#fff',
  sidebarcoloractive: '#fff',
  sideactiveitem: '#303030',
  sideactivehover: '#202020 !important',
  inputborder: '#707070 0.0625rem solid',
  inputback: '#303030 !important',
  inputcolor: '#e0e0e0 !important;',
  inputcoloractive: '#fff !important',
  dashboardcard: '#303030',
  textcontrast: '#c0c0c0 !important',
  inputbordercolor: '#707070',
  grayback: '#303030 !important',
  intercolor: '#707070',
  inactivecolor: '#606060',
  tabcolor: '#d0d0d0',
  hovercolor: '#f7f7f7',
  secondarycolor: '#f0f0f0',
  tagbackground: '#606060 !important',
  logomain: `url(${whiteLogo})`,
  boxShadow: '0px 1px 0px #404040',
  darkBorder: '1px solid #404040'
}

const DeviceNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const ToggleFileExplorerSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 2rem;
  
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 19px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 20px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
const StyledLabel = styled.span`
    font-size: 1rem;  
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: #303030;
`
const ToggleWrapper = styled.div`
  height: 100%;
`
export {
  AppFrameContainer,
  BodyWrapper,
  ContentWrapper,
  StyledHeader,
  TitleStyled,
  StyledFooter,
  StyledLabel,
  ToggleFileExplorerSwitch,
  DeviceNameWrapper,
  ToggleWrapper
}
