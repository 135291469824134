import * as React from 'react'
import styled from 'styled-components/macro'
import { ToastContainer, ToastContainerProps } from 'react-toastify'

const WrappedToastContainer = ({ className, ...rest }: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
)

export const StyledToastContainer = styled(WrappedToastContainer)`
  .Toastify__toast-container {}
  .Toastify__toast {
    background: none;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.10);
    padding: 0;
    margin-bottom: 1em;
  }
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__progress-bar {}
`
